module.exports.getBlog = `
{
  blogByHandle(handle: "community"){
    articles(first: 250,sortKey:PUBLISHED_AT, reverse:true){
      edges{
        node {
          id
          publishedAt
          title
          tags
          handle
          contentHtml
          image {
            altText
            originalSrc
          }
          authorV2 {
            name
          }
          seo {
            description
            title
          }
          metafields(identifiers: [
            { namespace: "key_take_aways_section", key: "keytakeawayssection" }
            { namespace: "updated_at", key: "updatedat" }
            { namespace: "products", key: "featured_products" }
            { namespace: "articles", key: "related_articles" }
            { namespace: "custom", key: "writer_profile" }
          ]) {
            key
            value
          }
        }
      }
    }
  }
}
`;

module.exports.getPrivacyPage = `
{
  pageByHandle(handle:"privacy-policy"){
  handle
  body
  }
}
`;

module.exports.getTerms = `
{
  pageByHandle(handle:"terms-and-conditions"){
  handle
  body
  }
}
`;

module.exports.getTermsAustralia = `
{
  pageByHandle(handle:"terms-conditions-australia-salon"){
    handle
    body
  }
}
`;

module.exports.allPages = `
{
pages(first: 250) {
    edges {
      node {
        title
        id
        bodySummary
        handle
      }
    }
  }
}
`;

module.exports.allShopifyProduct = `{
    allShopifyProduct {
    nodes {
        id
        handle
        title
        tags
        storefrontId
        metafields {
            key
            value
        }
       media {
					preview {
						image {
							altText
							originalSrc
							gatsbyImageData(width: 600)
						}
					}
					shopifyId
				}
        variants {
          storefrontId
          availableForSale
            storefrontId
            metafields {
                key
                value
            }
            price
            title
        }
        priceRangeV2 {
            maxVariantPrice {
                amount
                currencyCode
            }
            minVariantPrice {
                amount
                currencyCode
            }
        }
    }
  }
}
`;

module.exports.getShadeShotCollection = `
{
  allShopifyCollection(filter: {handle: {eq: "shade-shot"}}) {
    nodes {
      products {
        id
        handle
        tags
        title
        storefrontId
        productType
        options {
            name
            values
            shopifyId
        }
        priceRangeV2 {
            maxVariantPrice {
                amount
                currencyCode
            }
            minVariantPrice {
                amount
                currencyCode
            }
        }
        metafields {
            key
            value
        }
        media {
          preview {
           image {
							altText
							originalSrc
							gatsbyImageData
						}
          }
          shopifyId
        }
        variants {
            availableForSale
            storefrontId
            image {
                altText
                originalSrc
            }
            metafields {
                key
                value
            }
            price
            title
        }
      }
    }
  }
}`;

module.exports.allShopifyCollection = `
{
  allShopifyCollection {
    nodes {
      handle
      title
      metafields {
        key
        namespace
        value
      }
    }
  }
}`;

module.exports.getSellingPlanGroupsByIds = `
    query ($ids: [ID!]!) {
        nodes(ids: $ids) {
            ...on SellingPlanGroup {
                id
                name
                options
                sellingPlans(first: 10) {
                edges {
                      node {
                        id
                        name
                        pricingPolicies {
                          ... on SellingPlanFixedPricingPolicy {
                            adjustmentType
                            adjustmentValue {
                              ... on SellingPlanPricingPolicyPercentageValue {
                                percentage
                              }
                            }
                          }
                          ... on SellingPlanRecurringPricingPolicy {
                            adjustmentType
                            adjustmentValue {
                              ... on SellingPlanPricingPolicyPercentageValue {
                                percentage
                              }
                            }
                          }
                        }
                      }
                    }
                }
                products(first: 100) {
                    edges {
                        node {
                            id
                            handle
                        }
                    }
                }   
            }
        }
   }
`;

module.exports.getStoryblokEntry = (slug) => `
    {
        storyblokEntry(full_slug: { eq: "${slug}" }) {
            id
            name
            slug
            full_slug
            content
        }
    }
`;

module.exports.getBlogArticles = `
{
  blog (handle: "community") {
    articles (first: 150,sortKey:PUBLISHED_AT, reverse:true) {
      edges{
        node {
          id
          title
          tags
          handle
          image {
            altText
            originalSrc
          }
        }
      }
    }
  }
}
`;
