import React, { useState } from 'react'

import FaqQuestion from './FaqQuestion';

import './faq.scss'

const FaqContainer = ({ title, sections }) => {

  const [openQuestionIndex, setOpenQuestionIndex] = useState(null);
  const [openSection, setOpenSection] = useState(null);

  const descriptionFAQ = sections[0]?.title || '';
  const questionsFAQ = sections[0]?.questions || [];
  const firstSectionQuestions = questionsFAQ.slice(0, Math.ceil(questionsFAQ.length / 2));
  const secondSectionQuestions = questionsFAQ.slice(Math.ceil(questionsFAQ.length / 2));

  const handleToggle = (index, section) => {
    setOpenQuestionIndex(
      openQuestionIndex === index && openSection === section ? null : index
    );
    setOpenSection(section);
  };

  return (
    <section className='faq-container'>
      <h2>{title?.title}</h2>
      <p className='faq-description'>{descriptionFAQ}</p>
      <div className='faq-questions'>
        <ul className='faq-questions__section'>
          {
            firstSectionQuestions?.map((question, index) => (
              <FaqQuestion
                key={question?._uid}
                question={question}
                isQuestionOpen={openQuestionIndex === index && openSection === 'first'}
                onToggle={() => handleToggle(index, 'first')}
              />
            ))
          }
        </ul>
        <ul className='faq-questions__section'>
          {
            secondSectionQuestions?.map((question, index) => (
              <FaqQuestion
                key={question?._uid}
                question={question}
                isQuestionOpen={openQuestionIndex === index && openSection === 'second'}
                onToggle={() => handleToggle(index, 'second')}
              />
            ))
          }
        </ul>
      </div>
    </section>
  )
}

export default FaqContainer;
