import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';

import { getMetafield, isAvailable, isShadeShot } from '../../utils/helpers';
import { StoreContext } from '../../context/store-context';
import Loader from '../Loader';

const { getSellingPlanGroup } = require('../../utils/node-helpers');

const ProductListItem = lazy(() => import('./ProductListItem'));

const ProductListItemContainer = ({
	product,
	classname,
	cart,
	isHoverButton,
	withoutButton,
	collection,
	allSellingPlanGroups,
}) => {
	const { subscriptionsEnabled } = useContext(StoreContext),
		[sellingPlanGroup, setSellingPlanGroup] = useState(
			subscriptionsEnabled &&
				allSellingPlanGroups &&
				getSellingPlanGroup(
					product.storefrontId,
					allSellingPlanGroups.nodes
				)
		),
		[available, setAvailable] = useState(null),
		isRecommended = !!getMetafield(product, 'set_as_recommended'),
		isHide = getMetafield(product, 'is_hide'),
		isShot = isShadeShot(product);

	const activeGiftMetafield = collection?.metafields.find(
		(metafield) => metafield.key === 'active_gift_idea_and_inside_set'
	);

	const isActiveGiftAndInside =
		collection?.metafields.length > 0 &&
		activeGiftMetafield?.value &&
		JSON.parse(activeGiftMetafield.value);

	useEffect(() => {
		if (subscriptionsEnabled && allSellingPlanGroups) {
			setSellingPlanGroup(
				getSellingPlanGroup(
					product.storefrontId,
					allSellingPlanGroups.nodes
				)
			);
		}
	}, [allSellingPlanGroups]);

	useEffect(() => {
		setAvailable(isAvailable(product));
	}, []);

	return (
		<>
			{!product.title.includes('z_DISCONTINUED') &&
			!isHide &&
			product.handle !== 'permanent-colour-8-5' &&
			!product.handle.includes('_b') &&
			product.handle !== 'miracle-shot' &&
			product.handle !== 'chestnut-brunette-miracle-shot' &&
			!product.handle.includes('system-old') ? (
				<Suspense fallback={<div className='pdp-loader'><Loader/></div>}>
					<ProductListItem
						collectionName={collection?.title || ''}
						available={available}
						isShot={isShot}
						classname={classname}
						cart={cart}
						isHoverButton={isHoverButton}
						withoutButton={withoutButton}
						isRecommended={isRecommended}
						product={product}
						sellingPlanGroup={sellingPlanGroup}
						subscriptionsEnabled={subscriptionsEnabled}
						isActiveGiftAndInside={isActiveGiftAndInside}
					/>
				</Suspense>
			) : null}
		</>
	);
};

export default ProductListItemContainer;
