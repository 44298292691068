// External
import React, { useEffect } from 'react';
import cn from 'classnames';


const KlaviyoForm = ({
	id,
	inHeaderVisited,
	inHeaderNoVisited,
	inFooter,
	inHome,
}) => {
	const doRemoval = (klaviyos, all) => {
		for (let i = 0; i < klaviyos.length; ++i) {
			if (all) {
				klaviyos[i].remove();
			} else {
				i > 0 && klaviyos[i].remove();
			}
		}
	};
	const doRemovalMirSys = (klaviyos) => {
		for (let i = 0; i < klaviyos.length; ++i) {
			i > 1 && klaviyos[i].remove();
		}
	};
	const doRemovalSection = (query) => {
		if (typeof query !== 'string') return;
		const sectionKlaviyo = document.querySelectorAll(query);
		if (!sectionKlaviyo || sectionKlaviyo?.length <= 1) return;
		for (let i = 1; i < sectionKlaviyo.length; i++) {
			sectionKlaviyo[i].remove();
		}
	};
	const klaviyoSetter = (section, all = true) => {
		const klaviyos = document.querySelectorAll(
			`.klaviyo-form--${section} > div`
		);
		const mirSysKlav = document.querySelectorAll(
			'.pdp-klaviyo__container .klaviyo-form > div'
		);
		doRemoval(klaviyos, all);
		if (mirSysKlav.length) {
			doRemovalMirSys(mirSysKlav);
		}
		doRemovalSection('.blog-klaviyo .dynamic-klaviyo > .needsclick');
		doRemovalSection(
			'.footer__newsletter-text > .custom-klaviyo .dynamic-klaviyo > .needsclick'
		);
	};
	const removeKlaviyo = (all = true) => {
		klaviyoSetter('header-visited', all);
		klaviyoSetter('header-no-visited', all);
		klaviyoSetter('footer', all);
		klaviyoSetter('home', all);
	};
	const scheduleRemoval = (all = true) => {
		removeKlaviyo(all);
		setTimeout(() => removeKlaviyo(all), 1000);
		setTimeout(() => removeKlaviyo(all), 5000);
	};

	useEffect(() => {
		if (typeof window !== 'undefined') { 
			scheduleRemoval(false);
			window.addEventListener('popstate', scheduleRemoval);
			return () =>
				window.removeEventListener('popstate', scheduleRemoval);
		}
	}, [id]);

	const classes = cn(`dynamic-klaviyo klaviyo-form-${id}`, {
		'klaviyo-form--header-visited': inHeaderVisited,
		'klaviyo-form--header-no-visited': inHeaderNoVisited,
		'klaviyo-form--footer': inFooter,
		'klaviyo-form--home': inHome,
	});

	return <div className={classes} />;
};
export default KlaviyoForm;
