const addItemsQuizToCart = async (variantId) => {
	const itemsQuizShouldBeAdded =
		JSON.parse(sessionStorage.getItem('itemsQuizShouldBeAdded')) || [];

	if (itemsQuizShouldBeAdded.length > 0) {
		const variantExists = itemsQuizShouldBeAdded.some(
			(item) => item.variantId === variantId
		);
		if (variantExists) return;

		const newItems = [
			...itemsQuizShouldBeAdded,
			{
				variantId,
				addedFromQuiz: true,
			},
		];

		sessionStorage.setItem(
			'itemsQuizShouldBeAdded',
			JSON.stringify(newItems)
		);
	} else {
		sessionStorage.setItem('code', '10OFFDISCOUNTQUIZALLPRODUCTS');

		const firstItem = {
			variantId,
			addedFromQuiz: true,
		};

		sessionStorage.setItem(
			'itemsQuizShouldBeAdded',
			JSON.stringify([firstItem])
		);
	}
};

export default addItemsQuizToCart;
