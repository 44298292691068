import React, { Suspense, useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { StoreContext } from '../../../context/store-context';
import {
	getDiscountPrice,
	getMetafield,
	getPrice,
	sendProductClickEvent,
} from '../../../utils/helpers';
import getProductRating from '../../../helpers/getProductRating';
import isAWholeNumber from '../../../helpers/isAWholeNumber';
import Button from '../../Button';
import Loader from '../../Loader';
import Yotpo from '../../Yotpo';
import LocalImage from '../../LocalImage';
import RatingStars from '../../Rating/RatingStars';
import { useWindowWidth } from '../../../utils/hooks';

const ProductItem = (props) => {
	const width = useWindowWidth();
	const { subscriptionsEnabled, yotpoRatings } = useContext(StoreContext);
	const [groupFound, setGroupFound] = useState(
		props.sellingPlanGroup && Object.keys(props.sellingPlanGroup).length
	);
	const [discountPrice, setDiscountPrice] = useState(
		props.product.variants[0].price
	);
	const [isBundleVariant, setIsBundleVariant] = useState(
		!props.sellingPlanGroup && props.product.handle.includes('bundle')
	);
	const [isBundle, setIsBundle] = useState(false);
	const [priceValue, setPriceValue] = useState('');
	const isMiracle = getMetafield(props.product, 'is_miracle_system');
	const isSubscribeOnPDP = getMetafield(props.product, 'subscription_on_pdp');
	const [canSubscribe, setCanSubscribe] = useState(
		groupFound && subscriptionsEnabled && isSubscribeOnPDP
	);
	const isCustomizeAndPDP = getMetafield(
		props.product,
		'is_customize_and_pdp'
	);
	const showRating = getMetafield(props.product, 'is_rating_info');
	const ratingText = getMetafield(props.product, 'text_rating_info');
	const ratingColor = getMetafield(props.product, 'rating_color');
	const isMiracleSystemExpert = getMetafield(
		props.product,
		'is_miracle_system_expert'
	);
	const isMiracleSystemUltimate = getMetafield(
		props.product,
		'is_miracle_system_ultimate'
	);
	const productNavigation = getMetafield(
		props.product,
		'type_of_navigation_a_b_test'
	);

	const productHref = !productNavigation
		? `/products/${props.product.handle}`
		: `https://joshwoodcolour.com/products/${props.product.handle}`;

	const productRating = getProductRating(props.product, yotpoRatings);
	const reviewsAverage = productRating?.product_score;
	const reviewsCount = productRating?.total_reviews;

	function getPriceProduct() {
		return isMiracle
			? props.product.variants[0]
			: props.product.variants.filter(
					(variant) =>
						!variant.title.toLowerCase().includes('miracle')
			  )[0];
	}

	const [price, setPrice] = useState(getPriceProduct());
	useEffect(() => {
		if (props.sellingPlanGroup) {
			setGroupFound(Object.keys(props.sellingPlanGroup).length);
		}
	}, [props.sellingPlanGroup]);

	useEffect(() => {
		setCanSubscribe(groupFound && subscriptionsEnabled && isSubscribeOnPDP);
	}, [groupFound]);

	useEffect(() => {
		(props.product.options || []).forEach((option) => {
			const name = option.name.toLowerCase();
			if (name === 'bundle') setIsBundle(true);
		});
	}, []);

	useEffect(() => {
		if (canSubscribe) {
			const plan = props.sellingPlanGroup.sellingPlans.edges[0],
				productPrice = parseInt(props.product.variants[0].price),
				discount = getDiscountPrice(
					productPrice,
					plan.node.pricingPolicies[0].adjustmentValue.percentage
				);
			setDiscountPrice(discount);
		}
	}, [canSubscribe]);

	useEffect(() => {
		setPrice(getPriceProduct());
	}, [isBundle, isMiracle]);

	useEffect(() => {
		const priceIsWholeNumber = isAWholeNumber(price.price);
		setPriceValue(
			isSubscribeOnPDP
				? `£${
						isAWholeNumber(discountPrice)
							? parseInt(discountPrice)
							: discountPrice
				  }`
				: `£${priceIsWholeNumber ? parseInt(price.price) : price.price}`
		);
	}, [discountPrice, price, props.product]);

	const adjustPrice = () => {
		const priceOfProduct = price[canSubscribe ? 'price' : 'compareAtPrice'];
		const priceIsWholeNumber = isAWholeNumber(priceOfProduct);

		return priceIsWholeNumber ? 0 : 2;
	};

	const isCrossPriceInBundles =
		(!isMiracle && !canSubscribe && 'original-product-price--cross') ||
		((isMiracleSystemExpert || isMiracleSystemUltimate || !canSubscribe) &&
			'original-product-price--cross');
	const isMobile = width < 768;
	return (
		<>
			<li className='product-love-item'>
				<Link
					to={productHref}
					onClick={() => sendProductClickEvent(props.product)}
				>
					<div className='product-love-item__img'>
						{props.product.media[0] ? (
							<>
								{props.product.media[0].preview.image
									.gatsbyImageData ? (
									<GatsbyImage
										alt={props.product.title}
										image={
											props.product.media[0].preview.image
												.gatsbyImageData
										}
									/>
								) : (
									<LazyLoadImage
										src={
											props.product.media[0].preview.image
												.originalSrc
										}
										alt={props.product.title}
										effect='blur'
									/>
								)}
							</>
						) : (
							<LocalImage
								image='no-image.jpg'
								alt={props.product.title}
							/>
						)}

						<div className='product-love-item__img-tags'>
							{showRating && ratingText && !props.isModalUpSell && (
								<span
									style={{
										backgroundColor:
											ratingColor ||
											'rgba(236, 186, 86, 0.5)',
									}}
									className='pdp-info__rating'
								>
									{ratingText}
								</span>
							)}
						</div>
					</div>
				</Link>
				<div className='product-love-item__info'>
					{!isMiracle && props.product.rating && (
						<Suspense fallback={<Loader />}>
							<Yotpo rating={props.product.rating} />
						</Suspense>
					)}

					<div className='product-list-item-v2__rating'>
						<RatingStars
							rating={reviewsAverage}
							count={reviewsCount}
							showText={!isMobile}
						/>
					</div>
					<Link
						to={productHref}
						onClick={() => sendProductClickEvent(props.product)}
					>
						<h3 className='product-love-item__title'>
							{props.product.title}
						</h3>
					</Link>

					<div className='product-love-item__info-price flex'>
						{!props.isShot &&
						props.product.variants &&
						parseInt(price.price) ? (
							<>
								{price.compareAtPrice || canSubscribe ? (
									<div
										className={`original-product-price ${isCrossPriceInBundles}`}
									>
										{canSubscribe ? (
											<span className='current-product-price__text'>
												<span>From</span> {priceValue}
											</span>
										) : (
											getPrice(
												price[
													canSubscribe
														? 'price'
														: 'compareAtPrice'
												],
												props.cart,
												adjustPrice()
											)
										)}
									</div>
								) : null}
								<p
									className={`current-product-price ${
										(price.compareAtPrice ||
											canSubscribe) &&
										'current-product-price--red'
									}`}
								>
									{(!isMiracle ||
										(isMiracle && !canSubscribe)) &&
										!canSubscribe &&
										priceValue}
								</p>
							</>
						) : null}
					</div>

					<div className='product-love-item__info-btn'>
						<Button
							buttonText={
								isMiracle || isCustomizeAndPDP || canSubscribe
									? 'Choose shade'
									: 'add to bag'
							}
							product={props.product}
							isLink={
								isMiracle || isCustomizeAndPDP || canSubscribe
							}
							linkUrl={
								(isMiracle ||
									isCustomizeAndPDP ||
									canSubscribe) &&
								productHref
							}
							isBundle={isBundle}
							availableForSale={props.available}
							isPinkOutlineHome
							isModalUpSell={props.isModalUpSell}
							isPlp
							plpVersion='2'
							isHomepage
							id='products-customer-love-button'
						/>
					</div>
				</div>
			</li>
		</>
	);
};

export default ProductItem;
