import React, { useEffect } from 'react';
import cn from 'classnames';
import getUrlParams from '../../../../../helpers/getUrlParams';

const ProductSelectionLevelFilters = ({
	filtersList,
	activeType,
	setActiveType,
	setColourFilterApplied,
}) => {
	const handleClick = (item) => {
		setActiveType(item);
	};

	useEffect(() => {
		const colour = getUrlParams();
		if (colour) {
			const sanitizedTitle = colour
				.toLowerCase()
				.replace(/[^a-z0-9\s]/gi, '')
				.replace(/\s+/g, ' ');

			const number = sanitizedTitle.match(/\b\d{2}\b/g)?.[0] || 0;
			if (number > 55) {
				setActiveType('Light');
			} else {
				setActiveType('Dark');
			}
		}
		setColourFilterApplied(true);
	}, []);

	return (
		<div
			className='pdp-selection__filters
		pdp-selection-filters'
		>
			{filtersList
				.filter((item) => item !== '')
				.map((item) => (
					<div
						onClick={() => handleClick(item)}
						key={item}
						role='button'
						className={cn('pdp-selection-filters__item', {
							'pdp-selection-filters__item--active':
								item === activeType,
						})}
					>
						{item}
					</div>
				))}
		</div>
	);
};

export default ProductSelectionLevelFilters;
