// External
import React, { useContext, useEffect, useRef, useState } from 'react';

// Internal
import ProductMiracleButton from './ProductMiracleButton';
import Loader from '../../../../../../components/Loader';

import chevron from '../../../../../../../static/images/chevron.png';
import { useWindowWidth } from '../../../../../../utils/hooks';
import { StoreContext } from '../../../../../../context/store-context';

const MiracleSelect = React.lazy(() =>
	import('../../../../../../components/MiracleSelect')
);

const ProductMiracleActions = ({
	product,
	activeRadio,
	activeSecondLevel,
	recommendedProduct,
	isRecommendedSelected,
	setActiveModal,
	canSubscribe,
	selectedValue,
	setSelectedValue,
	sellingPlanGroup,
	optionsObjects,
	origin,
	levelValidator,
	setErrorSelectLevel,
	activeThirdLevel,
	activeFiveLevel,
	isHintActive,
}) => {
	const { setIsProductInfoButtonVisible, isProductInfoButtonVisible } =
		useContext(StoreContext);
	const width = useWindowWidth();
	const [isOpen, setIsOpen] = useState(false);
	const [rotateChevron, setRotateChevron] = useState(false);
	const handleSelect = () => {
		setIsOpen(!isOpen);
	};
	const isMobile = width < 550;

	const buttonRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsProductInfoButtonVisible(entry.isIntersecting);
			},
			{
				threshold: 0.2,
				rootMargin: '-80px 0px 0px 0px',
			}
		);

		if (buttonRef.current) {
			observer.observe(buttonRef.current);
		}

		return () => {
			if (buttonRef.current) {
				observer.unobserve(buttonRef.current);
			}
		};
	}, []);
	return (
		<div className='product-miracle-actions flex'>
			{activeRadio === 'auto-delivery' && activeSecondLevel !== null && (
				<div
					className='product-miracle-select'
					onClick={() => {
						setRotateChevron((currenValue) => !currenValue);
					}}
					onBlur={() => {
						setRotateChevron(false);
					}}
				>
					<React.Suspense fallback={<Loader />}>
						<MiracleSelect
							type='pdp'
							{...{
								optionsObjects,
								selectedValue,
								setSelectedValue,
								handleSelect,
								origin,
							}}
						/>
					</React.Suspense>
					<img
						className='select-chevron'
						src={chevron}
						alt='chevron for product select'
						style={{
							transform: rotateChevron
								? 'rotate(0deg)'
								: 'rotate(180deg)',
						}}
					/>
				</div>
			)}

			<div className='product-miracle-actions-v2 flex'>
				<div
						style={{
							opacity: isProductInfoButtonVisible ? 0 : 1,
							visibility: isProductInfoButtonVisible
								? 'hidden'
								: 'visible',
							transition:
								'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
						}}
						id='add-to-bag-sticky-button'
				>
					<ProductMiracleButton
						{...{
							product,
							activeSecondLevel,
							recommendedProduct,
							isRecommendedSelected,
							setActiveModal,
							canSubscribe,
							activeRadio,
							activeThirdLevel,
							activeFiveLevel,
							selectedValue,
							sellingPlanGroup,
							levelValidator,
							setErrorSelectLevel,
							isHintActive,
						}}
						oneTime={activeRadio !== 'auto-delivery'}
					/>
				</div>

				{isMobile && (
					<div ref={buttonRef}>
						<ProductMiracleButton
							{...{
								product,
								activeSecondLevel,
								recommendedProduct,
								isRecommendedSelected,
								setActiveModal,
								canSubscribe,
								activeRadio,
								activeThirdLevel,
								activeFiveLevel,
								selectedValue,
								sellingPlanGroup,
								levelValidator,
								setErrorSelectLevel,
							}}
							oneTime={activeRadio !== 'auto-delivery'}
							className='mobile'
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default ProductMiracleActions;
