// External
import React, { useState, useRef, useEffect } from 'react';
import { navigate } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Internal
import { getQuery } from '../../utils/helpers';
import trendingSearches from '../../constants/trendingSearches';

import './SearchModal.scss';

const SearchModal = ({ closeModal, isHeaderV2 = false }) => {
	const initialQuery = decodeURIComponent(getQuery('filter') ?? '');
	const [query, setQuery] = useState('');
	const searchInputRef = useRef(null);

	const onSubmitSearch = (event, searchParameter) => {
		event.preventDefault();
		const searchValue = searchParameter.trim();
		const isValidSearch = searchValue.length > 0;
		if (!isValidSearch) return;
		const queryParameter = encodeURIComponent(searchValue);
		navigate(`/search?filter=${queryParameter}`);
		closeModal();
	};

	useEffect(() => {
		if (searchInputRef.current) {
			searchInputRef.current.focus();
		}
	}, []);

	return (
		<div className='search-modal-container' onClick={() => closeModal()}>
			<div
				className={`search-modal ${isHeaderV2 && 'search-modal-v2'}`}
				onClick={(e) => e.stopPropagation()}
			>
				<div className='search-modal__search-box-container'>
					<form
						className='search-modal__search-box'
						onSubmit={(event) => onSubmitSearch(event, query)}
					>
						<input
							type='search'
							value={query}
							onChange={(e) => setQuery(e.target.value)}
							// eslint-disable-next-line jsx-a11y/no-autofocus
							autoFocus
							ref={searchInputRef}
						/>
						<button type='submit'>
							<LazyLoadImage
								src='/images/searchIcon.png'
								alt='search icon for search-modal'
								effect='blur'
							/>
						</button>
					</form>
					<button
						className='search-modal__search-close'
						onClick={() => closeModal()}
					>
						<LazyLoadImage
							src='/images/modal-close.svg'
							alt='close icon for search-modal'
							effect='blur'
						/>
					</button>
				</div>
				<div className='search-modal__search-links-container'>
					<h4>Trending Searches</h4>
					{trendingSearches.map((trending) => (
						<button
							className='search-modal__search-link'
							onClick={(event) => onSubmitSearch(event, trending)}
							key={trending}
						>
							<div>
								<LazyLoadImage
									src='/images/mobile-search-icon-dark.svg'
									alt='search icon'
									effect='blur'
								/>
							</div>
							<p>{trending}</p>
						</button>
					))}
				</div>
			</div>
		</div>
	);
};

export default SearchModal;
