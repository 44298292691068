import React, { useContext } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link } from 'gatsby';
import TimelyButton from '../../components/TimelyButton';
import { StoreContext } from '../../context/store-context';
import './ExpertArticlePage.scss';

const ExpertArticlePage = ({ expert = {} }) => {
	const { setModal, showModal } = useContext(StoreContext);
	const profile = (expert?.insta || '').replace('@', '');
	const expertUrl = `https://joshwoodcolour.com/pages/meet-the-experts/${expert.handle}`;
	const expertTags = `${(expert.tags || [''])[0]}`;
	const instaLink = expert?.insta
		? `https://www.instagram.com/${profile}/`
		: '';
	const imgUrl = expert?.img_url ? `https:${expert?.img_url}` : '';

	const handleShowModal = () => {
		setModal(
			{
				link: expert?.link,
			},
			'expert'
		);
		showModal(true);
	};

	return (
		<div className='expert-page'>
			<div className='container'>
				<Link
					className='expert-page__link'
					to='/pages/meet-the-experts'
				>
					all stylists
				</Link>
				<div className='expert-first-screen'>
					<div className='expert-image expert-image--desktop'>
						{expert.image_expert ? (
							<LazyLoadImage
								src={expert.image_expert}
								alt={expert.name}
								effect='blur'
							/>
						) : (
							<LazyLoadImage
								src='/images/empty-expert.png'
								alt='Empty Expert'
								effect='blur'
							/>
						)}
					</div>
					<div className='expert-first-screen__right'>
						<div className='expert__title'>
							<h1>{expert.name}</h1>
							<div className='flex'>
								<p className='expert__profession'>
									{(expert.tags || ['0'])[0]}
								</p>
								{expert.insta && (
									<a
										className='expert-page__insta'
										href={instaLink}
									>
										{expert.insta}
									</a>
								)}
							</div>
						</div>
						<div className='expert-image expert-image--mobile'>
							{expert.image_expert ? (
								<LazyLoadImage
									src={expert.image_expert}
									alt={expert.name}
									effect='blur'
								/>
							) : (
								<LazyLoadImage
									src='/images/empty-expert.png'
									alt='Empty expert'
									effect='blur'
								/>
							)}
						</div>
						<div className='expert__description'>
							<p>{expert.description}</p>
						</div>

						{expert.block_review_text_1 && (
							<div className='expert-review'>
								<div className='expert-review__text'>
									{expert.block_review_text_1}
								</div>
								{expert.block_review_name_1 && (
									<div className='expert-review__name'>
										{expert.block_review_name_1}
									</div>
								)}
							</div>
						)}
						{expert.link ? (
							<div
								className='expert-button-book'
								onClick={handleShowModal}
							>
								<span>book appointment</span>
							</div>
						) : (
							<TimelyButton
								classNames='experts-button-book'
								html={`<script>new timelyButton("joshwoodcolour", {"style": "dark"});</script>`}
							/>
						)}
					</div>
				</div>
				{expert.block_review_text_2 && (
					<div className='expert-review expert-review--center'>
						<div className='expert-review__text'>
							{expert.block_review_text_2}
						</div>
						{expert.block_review_name_2 && (
							<div className='expert-review__name'>
								{expert.block_review_name_2}
							</div>
						)}
					</div>
				)}
				{expert.block_image_1 ||
					expert.block_image_2 ||
					(expert.block_image_3 && (
						<div className='expert-gallery'>
							{expert.block_image_1 ||
								(expert.block_image_2 && (
									<div className='expert-gallery__top'>
										{expert.block_image_1 && (
											<div className='expert-gallery__img expert-gallery__img--horizontal'>
												<LazyLoadImage
													src={expert.block_image_1}
													alt='Expert Block'
													effect='blur'
												/>
											</div>
										)}
										{expert.block_image_2 && (
											<div className='expert-gallery__img expert-gallery__img--vertical'>
												<LazyLoadImage
													src={expert.block_image_2}
													alt='Expert Block Img'
													effect='blur'
												/>
											</div>
										)}
									</div>
								))}
							{expert.block_image_3 && (
								<div className='expert-gallery__bottom'>
									<div className='expert-gallery__img expert-gallery__img--slim'>
										<LazyLoadImage
											src={expert.block_image_3}
											alt='Expert Block'
											effect='blur'
										/>
									</div>
								</div>
							)}
						</div>
					))}
			</div>
			<div
				itemScope
				itemType='http://schema.org/Person'
				style={{ display: 'none' }}
			>
				<div itemProp='name'>{expert.name}</div>
				<LazyLoadImage
					src={imgUrl}
					itemProp='image'
					alt='expert appearance'
					effect='blur'
				/>
				<a href={expertUrl} itemProp='url'>
					{expert.name}
				</a>
				<link itemProp='sameAs' href={instaLink} />
				<span itemProp='jobTitle'>{expertTags}</span>
				<span
					itemProp='worksFor'
					itemType='http://schema.org/Organization'
				>
					Josh Wood Colour
				</span>
			</div>
		</div>
	);
};

export default ExpertArticlePage;
