export const setHrefLangTag = (id) => {
	const link = document.createElement('link');
	link.href = window?.location?.href || 'https://joshwoodcolour.com';
	link.hreflang = 'en';
	link.id = id || 'new-hreflang';

	const headTag = document.getElementsByTagName('head')[0];
	if (!headTag) return;
	headTag.appendChild(link);
};

export const removeHrefLangTag = (id) => {
	const link = document.getElementById(id || 'new-hreflang');
	const headTag = document.getElementsByTagName('head')[0];
	if (!headTag || !link) return;
	headTag.removeChild(link);
};
