import React from 'react';

const SocialItem = ({ item }) => (
	<li>
		<a href={item.url} target='_blank' rel='noopener noreferrer'>
			<img
				alt='social'
				src={`/images/socials/${(item.title || '').toLowerCase()}.svg`}
				width='30'
				height='30'
			/>
		</a>
	</li>
);
export default SocialItem;
