import React, { Fragment, useState } from 'react'
import ReactPlayer from 'react-player/lazy';

import ProductVideoContainer from './ProductVideoContainer';

const ProductVideos = ({ videos = [], allSellingPlanGroups }) => {
  const [expandedVideos, setExpandedVideos] = useState({});
  const [expandedDescription, setExpandedDescription] = useState({});

  const handleMoreProducts = (videoTitle) => {
    setExpandedVideos(prev => ({
      ...prev,
      [videoTitle]: !prev[videoTitle]
    }));
  }

  const handleExpandDescription = (videoTitle) => {
    setExpandedDescription(prev => ({
      ...prev,
      [videoTitle]: !prev[videoTitle]
    }));
  }

  return (
    <div className='application-videos__videos-container'>
      {videos?.map((video) => (
        <div className='video-item' key={video?.title}>
          <h3 className='video-item__title'>{video?.title}</h3>

          <div className='video-item__video'>
            <ReactPlayer
              url={`https://www.youtube.com/watch?v=${video?.urlVideo}`}
              width='100%'
              height='100%'
              controls
              config={{
                youtube: {
                  playerVars: {
                    autoplay: 0,
                    modestbranding: 1,
                    showinfo: 1,
                  },
                },
              }}
              style={{
                cursor: 'pointer',
              }}
            />
          </div>

          {
            video?.description !== '' && (
              <>
                <div className={`video-item__description ${expandedDescription[video?.title] ? 'expanded' : ''}`}>
                  <p>{video?.description}</p>
                </div>

                <button
                  className={`video-item__more-products ${expandedDescription[video?.title] ? 'rotate' : ''}`}
                  onClick={() => handleExpandDescription(video?.title)}
                >
                  {expandedDescription[video?.title] ? 'Read less' : 'Read more'}
                </button>
              </>
            )
          }

          <div className='video-item__products'>
            {video?.products?.[0] && (
              <ProductVideoContainer
                allSellingPlanGroups={allSellingPlanGroups}
                product={video.products[0]}
              />
            )}

            <div className={`video-item__additional-products ${expandedVideos[video?.title] ? 'expanded' : ''}`}>
              {video?.products?.slice(1).map((product) => (
                <Fragment key={product?.storefrontId}>
                  <ProductVideoContainer
                    allSellingPlanGroups={allSellingPlanGroups}
                    product={product}
                  />
                </Fragment>
              ))}
            </div>

            {video?.products?.length > 1 && (
              <button
                className={`video-item__more-products ${expandedVideos[video?.title] ? 'rotate' : ''}`}
                onClick={() => handleMoreProducts(video.title)}
              >
                {expandedVideos[video?.title] ? 'Fewer products' : 'More products'}
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default ProductVideos;