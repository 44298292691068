/* eslint-disable */
import React from 'react';
import SelectListItem from './SelectListItem';
import './ModalPermanentDesktop.scss';

const SelectList = ({
	data,
	currentStep,
	productHandle,
	setSelectedBaseProduct,
	selectedBaseColour,
	setSelectedBaseColour,
	selectedShot,
	setSelectedShot,
	setShots,
	productsList,
}) => {
	const isFirstStep = currentStep === 1;
	const decimalRegex = /[-+]?([0-9]*\.[0-9]+|[0-9]+)/;
	const list = isFirstStep
		? data.sort((a, b) =>
				parseFloat(a.title.match(decimalRegex)).toFixed(1) >
				parseFloat(b.title.match(decimalRegex)).toFixed(1)
					? 1
					: -1
		  )
		: data;

	return (
		<div className='modal-permanent__list modal-permanent-list' style={{ overflowY: 'auto' }}>
			{(list || []).map((item) => {
				const onVariantTitle =
					selectedBaseColour.title === '2.0' ||
					selectedBaseColour.title === '3.0'
						? 'Black Onyx'
						: item.title;
				const image = isFirstStep
					? `pdp/base/base-${item.handle.split('-')[2]}-${
							item.handle.split('-')[3]
					  }.webp`
					: `pdp/${selectedBaseColour.title}/${onVariantTitle
							.split('-')[0]
							.toLowerCase()
							.replace(' ', '-')}.webp`;
				const title = isFirstStep
					? parseFloat(item.title.match(decimalRegex)).toFixed(1)
					: onVariantTitle.split('-')[0];
				const description = isFirstStep
					? item.title.split('-')[1].trim()
					: null;

				return (
					<SelectListItem
						isFirstStep={isFirstStep}
						key={
							isFirstStep
								? item.variants[0].storefrontId
								: item.storefrontId
						}
						item={item}
						image={image}
						title={title}
						description={description}
						productHandle={productHandle}
						currentStep={currentStep}
						setSelectedBaseProduct={setSelectedBaseProduct}
						selectedBaseColour={selectedBaseColour}
						setSelectedBaseColour={setSelectedBaseColour}
						selectedShot={selectedShot}
						setSelectedShot={setSelectedShot}
						setShots={setShots}
						productsList={productsList}
					/>
				);
			})}
		</div>
	);
};

export default SelectList;
