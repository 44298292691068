export default [
	{
		imgSrc: 'https://cdn.shopify.com/s/files/1/0266/7223/4576/files/Untitled_design_52.png',
		title: 'Shop Hair Care',
		handle: '/collections/care',
	},
	{
		imgSrc: 'https://cdn.shopify.com/s/files/1/0266/7223/4576/collections/81ce472fe4024cdbabf711e9c5d74907.jpg?v=1739810913',
		title: 'Shop Hair Colour',
		handle: '/collections/colour',
	},
	{
		imgSrc: 'https://cdn.shopify.com/s/files/1/0266/7223/4576/collections/f8c7600aa5783129bc2b352fe89203ad.png?v=1739810914',
		title: 'Shop Gloss',
		handle: '/collections/gloss-and-glaze',
	},
	{
		imgSrc: 'https://cdn.shopify.com/s/files/1/0266/7223/4576/collections/9509cfc2edd76d2c51715acd20749b4f.jpg?v=1739810914',
		title: 'Shop Root Touch-up',
		handle: '/collections/root-touch-up',
	},
];
