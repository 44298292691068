/* eslint-disable */
import React from 'react';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import packageJson from '../../../package.json';

const SEO = ({
	title,
	location,
	page,
	product,
	article,
	pageType,
	noindex,
	noindexContent = 'noindex,nofollow',
	customCanonical = null,
}) => {
	const replacePath = (path) => path.replace(/\/$/, '');
	const data = useStaticQuery(query);
	const url = data?.site?.siteMetadata?.siteUrl || '';

	let { pathname, search } = location;
	const canonicalHref = pathname.includes('search')
		? replacePath(url + pathname + search)
		: replacePath(url + pathname);
	let canonical = [
		{ rel: 'canonical', href: customCanonical ?? canonicalHref },
		{ rel: 'alternate', href: url + pathname + search, hrefLang: 'en' },
	];
	let defaultTitle;
	let defaultDescription;
	let aggregateRating;

	const productHasVariants = product?.variants?.length > 1;

	switch (pageType) {
		case 'PDP':
			const ratingCount = product.metafields.find(
				(field) => field.key === 'reviews_count'
			);
			const ratingField = product.metafields.find(
				(field) => field.key === 'reviews_average'
			);
			if (ratingCount?.value > 0 && ratingField) {
				aggregateRating = {
					'@type': 'AggregateRating',
					ratingValue: ratingField.value,
					reviewCount: ratingCount.value,
				};
			}
			defaultTitle = `${title} by Josh Wood Colour`;
			defaultDescription = `Shop ${title} by Josh Wood Colour. Professional home hair colour, semi-permanent hair dye, grey root touch-up, and more.`;
			break;
		case 'PLP':
			defaultTitle = `${title} by Josh Wood Colour`;
			defaultDescription = `Browse & shop ${title} by Josh Wood Colour. Professional home hair colour, semi-permanent hair dye, grey root touch-up, and more.`;
			break;
		default:
			if (typeof title !== 'undefined') {
				defaultTitle = `${title} Josh Wood Home Hair Colour`;
				defaultDescription = `${title}. Discover professional home hair colour, semi-permanent hair dye, grey root touch-up, and more by Josh Wood Colour.`;
			} else {
				let titleByHandle = '';
				if (pathname) {
					titleByHandle = pathname.slice(
						pathname.lastIndexOf('/') + 1
					);
					titleByHandle = titleByHandle.split('-').join(' ');
					titleByHandle =
						titleByHandle.charAt(0)?.toUpperCase() +
						titleByHandle.slice(1);
				}
				defaultTitle = `${titleByHandle} - Josh Wood Home Hair Colour`;
				defaultDescription = `${titleByHandle}. Discover professional home hair colour, semi-permanent hair dye, grey root touch-up, and more by Josh Wood Colour.`;
			}
			break;
	}

	const newArray = [];
	for (const key in page) {
		if (key !== 'title') {
			let newKey = key.replace('_', ':');
			if (newKey.includes('secure')) {
				newKey = newKey.replace('_', ':');
			}
			const newObj = {
				[newKey.includes('og:') ? 'property' : 'name']: newKey,
				content: page[key],
			};
			newArray.push(newObj);
		}
	}
	const getProductImage = () => {
		if (!Array.isArray(product.images))
			return ['https://joshwoodcolour.com/images/no-image.jpg'];
		let images = [];
		for (const image of product.images) {
			images.push(image.originalSrc);
		}
		return images;
	};

	return (
		<>
			<Helmet
				meta={newArray}
				link={canonical}
				title={page?.title ? page.title : defaultTitle}
			>
				{/* 	<title>{page?.title ? page.title : defaultTitle}</title> */}
				<meta
					name='description'
					content={
						page?.description
							? page.description
							: defaultDescription
					}
				/>

				{noindex && <meta name='robots' content={noindexContent} />}
				{noindex && <meta name='googlebot' content={noindexContent} />}

				{product && (
					<link
						rel='preload'
						as='image'
						href={product.media[0].preview.image.originalSrc}
						fetchPriority='high'
					/>
				)}
				{pathname === '/' && (
					<script type='application/ld+json'>
						{JSON.stringify({
							'@context': 'https://schema.org/',
							'@type': 'WebSite',
							name: 'Josh Wood Colour',
							url: 'https://joshwoodcolour.com',
							potentialAction: {
								'@type': 'SearchAction',
								target: 'https://joshwoodcolour.com/search?filter={search_term_string}',
								'query-input':
									'required name=search_term_string',
							},
						})}
					</script>
				)}
				{pathname === '/' && (
					<script type='application/ld+json'>
						{JSON.stringify({
							'@context': 'https://schema.org',
							'@type': 'Corporation',
							name: 'Josh Wood Colour',
							url: 'https://joshwoodcolour.com',
							logo: 'https://cdn.shopify.com/s/files/1/0266/7223/4576/files/josh-wood-logo_1200x1200.png',
							sameAs: [
								'https://en-gb.facebook.com/JoshWoodColour/',
								'https://twitter.com/JoshWoodColour',
								'https://www.instagram.com/joshwoodcolour',
								'https://www.youtube.com/channel/UCXp8H8zsPLgaHZnukBwcgHQ',
							],
						})}
					</script>
				)}
				{product && !productHasVariants && (
					<script type='application/ld+json'>
						{JSON.stringify({
							'@context': 'https://schema.org/',
							'@type': 'Product',
							productID: product.storefrontId?.replace(
								'gid://shopify/Product/',
								''
							),
							name: product.title,
							image: getProductImage(),
							description: product.description,
							brand: 'Josh Wood Colour',
							aggregateRating,
							offers: [
								{
									'@type': 'Offer',
									url: `https://joshwoodcolour.com/products/${product.handle}`,
									priceCurrency:
										product.priceRangeV2.minVariantPrice
											.currencyCode || 'GBP',
									price: product.priceRangeV2.minVariantPrice
										.amount,
									availability: 'https://schema.org/InStock',
								},
							],
						})}
					</script>
				)}
				{product && productHasVariants && (
					<script type='application/ld+json'>
						{JSON.stringify({
							'@context': 'https://schema.org/',
							'@type': 'ProductGroup',
							productGroupID: product.storefrontId?.replace(
								'gid://shopify/Product/',
								''
							),
							name: product.title,
							image: getProductImage(),
							description: product.description,
							url: `https://joshwoodcolour.com/products/${product.handle}`,
							brand: 'Josh Wood Colour',
							aggregateRating,
							hasVariant: product.variants.map((variant) => ({
								'@type': 'Product',
								sku: variant.sku,
								name: variant.title,
								image: variant.image?.originalSrc,
								description: variant.description,
								brand: 'Josh Wood Colour',
								aggregateRating,
								offers: [
									{
										'@type': 'Offer',
										url: `https://joshwoodcolour.com/products/${
											product.handle
										}?colour=${variant.title
											.toLowerCase()
											.replace(/[^a-z0-9\s]/gi, '')
											.replace(/\s+/g, '+')}`,
										priceCurrency:
											product.priceRangeV2.minVariantPrice
												.currencyCode || 'GBP',
										price: variant.price,
										availability:
											'https://schema.org/InStock',
									},
								],
							})),
						})}
					</script>
				)}
				{article && (
					<script type='application/ld+json'>
						{JSON.stringify({
							'@context': 'https://schema.org/',
							'@type': 'Article',
							headline: article.title,
							description: article?.seo?.description,
							image: {
								'@type': 'ImageObject',
								url: article.image?.originalSrc ?? '',
							},
							author: {
								'@type': 'Person',
								name: article.authorV2?.name,
							},
							datePublished: article.publishedAt,
						})}
					</script>
				)}
				<meta name='build-version' content={packageJson.version} />
			</Helmet>
		</>
	);
};

const query = graphql`
	{
		site {
			siteMetadata {
				title
				siteUrl
			}
		}
	}
`;

export default SEO;
