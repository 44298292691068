import React from 'react';
import { Link } from 'gatsby';
import { getShopifyImage } from 'gatsby-source-shopify';
import { GatsbyImage } from 'gatsby-plugin-image';

import BlogPageTags from '../BlogPageTags/BlogPageTags';
import LocalImageOptimized from '../../../../components/LocalImage/LocalImageOptimized';

import './BlogPageGridItem.scss';

const BlogGridPageItem = ({
	article,
	withoutTags,
	classname,
	isFromSearchPage = false,
}) => {
	const blogLink = article?.storyblok_blog
		? `/${article.full_slug}`
		: `/blogs/community/${article.handle}`;

	const blogIsFromShopify = article.image?.originalSrc || isFromSearchPage;
	const blogImage =
		blogIsFromShopify && !article?.storyblok_blog
			? getShopifyImage({
					image: isFromSearchPage
						? { originalSrc: article.image }
						: article.image,
					width: 800,
					height: 600,
					layout: 'fixed',
			  })
			: article.image;

	return (
		<div
			className={`blog-grid-item ${classname && classname}`}
			data-tags={article.tags}
		>
			<Link to={blogLink}>
				<div className='blog-grid-item__img'>
					{article?.storyblok_blog ? (
						<LocalImageOptimized
							imageUrl={blogImage}
							alt={article.imageAlt}
							loadingType='eager'
						/>
					) : (
						<GatsbyImage image={blogImage} alt={article.title} />
					)}
				</div>
				{!withoutTags && article.tags && article.tags.length > 0 && (
					<BlogPageTags tags={article.tags} />
				)}
				<h3 className='blog-grid-item__title'>{article.title}</h3>
			</Link>
		</div>
	);
};

export default BlogGridPageItem;
