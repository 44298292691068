import React from 'react';

const ListFinderItem = ({
	store,
	selectedPlace,
	setSelectedPlace,
	setDirections,
}) => {
	const isWebStore = store?.type === 'web';

	const content = (
		<>
			<h3>{store?.store_name}</h3>
			<p className='store-finder__locations__list__item--address'>
				{store?.address}
			</p>
			{store?.phone && (
				<p className='store-finder__locations__list__item--phone'>
					{store?.phone}
				</p>
			)}
			{store?.website && (
				<section
					className='container-website'
					onClick={() => {
						window.open(
							store?.website,
							'_blank',
							'noopener noreferrer'
						);
					}}
				>
					<p>{store?.website}</p>
				</section>
			)}
		</>
	);

	return isWebStore ? (
		<div
			className='store-finder__locations__list__item'
			onClick={() => {
				window.open(store?.website, '_blank', 'noopener noreferrer');
			}}
		>
			{content}
		</div>
	) : (
		<div
			className={`store-finder__locations__list__item ${selectedPlace?.store_number_id === store?.store_number_id &&
				'store-finder__locations__list__item--selected'
				}`}
			onClick={() => {
				setSelectedPlace(store);
				setDirections(store);
			}}
		>
			{content}
		</div>
	);
};

export default ListFinderItem;
