import React from 'react';
import { Link } from 'gatsby';

import TimelyButton from '../../../components/TimelyButton';

const SalonHeaderButtons = () => (
	<div className='salon-header-buttons'>
		{/* <div
		 className="salon-header-button salon-header-button--active
        appointment-button salon-header-button--transparent">
		 <script>new timelyButton("joshwoodcolour",
        {"style":"dark"});</script>
		</div> */}
		<TimelyButton
			html={
				'<script>new timelyButton("joshwoodcolour", {"style":"dark"});</script>'
			} // eslint-disable-line
			classNames='salon-header-buttons__timely shb--order-1'
		/>
		<Link
			className='salon-header-button shb--order-2'
			to='/pages/meet-the-experts'
		>
			<span className='salon-header-button__content'>Meet the Team</span>
		</Link>
		<Link
			className='salon-header-button shb--order-3'
			to='/pages/salon-price-list'
		>
			<span className='salon-header-button__content'>
				Services Available
			</span>
		</Link>
		<Link
			className='salon-header-button salon-header-button--gift shb--order-4' // eslint-disable-line
			to='/pages/gift-card'
		>
			<span className='salon-header-button__content'>Gift Cards</span>
		</Link>
	</div>
);

export default SalonHeaderButtons;
