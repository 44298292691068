// External
import React, { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Internal
import SEO from '../../components/SEO';
import Klaviyo from '../../components/Klaviyo';
import KeyTakeAways from '../../components/KeyTakeAways/Index';
import ArticleFeaturedProducts from '../../components/ArticleFeaturedProducts';
import ArticleRelatedArticles from '../../components/ArticleRelatedArticles/ArticleRelatedArticles';
import Breadcrumb from '../../components/BreadCrumb/Index';
import HTMLParser from '../../components/HTMLParser';

import { getMetafieldsObject, convertArticleDate } from '../../utils/helpers';
import { useMetaObject, useMetaImage } from '../../utils/hooks';
import { BLOG_SECTION_BREADCRUMB } from '../../constants/breadcrumbs';

import useFetchAllproducts from '../../utils/useFetchAllproducts';

import './article.scss';

const Index = ({
	location,
	pageContext: { article, page, relatedArticles },
}) => {
	const articleMetafield = getMetafieldsObject(article?.metafields);
	const products = useFetchAllproducts();

	const { metaObject } = useMetaObject(articleMetafield?.writer_profile);

	const metaObjectFields = getMetafieldsObject(metaObject?.fields);
	const { metaImage, setUpdate } = useMetaImage(
		metaObjectFields?.writer_avatar
	);
	useEffect(() => {
		if (metaObjectFields?.writer_avatar && !metaImage) {
			setUpdate((prev) => !prev);
		}
	}, [metaObjectFields, metaImage]);

	return (
		<section className='blog-article--container'>
			<SEO page={page} location={location} article={article} />
			<div className='container'>
				<div className='article'>
					<Breadcrumb
						section={BLOG_SECTION_BREADCRUMB}
						article={article}
					/>
					<h1 className='article__title'>{article.title}</h1>
					<div className='article__author-information'>
						{metaImage && (
							<LazyLoadImage
								src={metaImage}
								alt="writer's avatar"
								effect='blur'
							/>
						)}
						<h6>
							{article?.authorV2?.name && (
								<span>By {article.authorV2.name}.</span>
							)}
							{article?.publishedAt &&
								`
								Created on 
								${convertArticleDate(article.publishedAt)}.  `}
							{articleMetafield?.updatedat &&
								`
								Updated on
								${convertArticleDate(articleMetafield.updatedat)}
							`}
						</h6>
					</div>
					<HTMLParser
						contentHtml={article.contentHtml || ''}
						defaultImageAlt={article.title}
						containerClassName='article-main-content'
					/>
					<div className='custom-klaviyo blog-klaviyo'>
						<Klaviyo id={process.env.KLAVIYO_FOOTER_FORM_ID} />
					</div>
					{articleMetafield?.keytakeawayssection && (
						<KeyTakeAways
							text={articleMetafield.keytakeawayssection}
						/>
					)}
					<br />
					<div className='article__author-biography'>
						{metaImage && (
							<LazyLoadImage
								src={metaImage}
								alt="writer's avatar profile"
								effect='blur'
							/>
						)}
						<div>
							<span>
								<a
									href={
										metaObjectFields?.writer_link &&
										metaObjectFields?.writer_link
									}
								>
									{metaObjectFields?.full_name &&
										`By ${metaObjectFields?.full_name}. `}
								</a>
							</span>
							{metaObjectFields?.writer_bio && (
								<p>{metaObjectFields.writer_bio}</p>
							)}
						</div>
					</div>
				</div>
			</div>
			<ArticleRelatedArticles relatedArticles={relatedArticles} />
			{products?.length > 0 && (
				<ArticleFeaturedProducts
					article={article}
					products={products}
				/>
			)}
		</section>
	);
};

export default Index;
