// External
import React from 'react';
import loadable from '@loadable/component';
import { Link } from 'gatsby';

// Internal
import ExpertsHeroBanner from './ExpertsHeroBanner';
import TimelyButton from '../../components/TimelyButton';
import Breadcrumb from '../../components/BreadCrumb/Index';

import { EXPERTS_SECTION_BREADCRUMB } from '../../constants/breadcrumbs';

import './Experts.scss';

const Filter = loadable(() => import('../../components/Filter/index'));
const ExpertsGrid = loadable(() => import('./ExpertsGrid/ExpertsGrid'));

const ExpertsPage = ({ experts, headerText }) => {
	const filtersList = [
		{ tags: ['creative master'] },
		{ tags: ['master'] },
		{ tags: ['principal'] },
		{ tags: ['advocate'] },
		{ tags: ['varderer'] },
		{ tags: ['beautician'] },
	];
	const workTime = [
		{
			day: 'Monday',
			hours: '09:00 – 19:00',
		},
		{
			day: 'Tuesday',
			hours: '09:00 – 19:00',
		},
		{
			day: 'Wednesday',
			hours: '09:00 – 19:00',
		},
		{
			day: 'Thursday',
			hours: '09:00 – 19:00',
		},
		{
			day: 'Friday',
			hours: '09:00 – 19:00',
		},
		{
			day: 'Saturday',
			hours: '09:00 – 18:00',
		},
		{
			day: 'Sunday',
			hours: 'closed',
		},
	];

	return (
		<section className='experts'>
			<section className='container'>
				<nav aria-label='breadcrumb'>
					<Breadcrumb section={EXPERTS_SECTION_BREADCRUMB} />
				</nav>
				<h1 className='experts__title'>{headerText}</h1>
			</section>
			<ExpertsHeroBanner />
			<section className='container'>
				<section className='experts__row'>
					<article className='experts__text'>
						<p>
							If you would like help planning please contact us
							any time and we would be delighted to help.
						</p>
						<h3>Salon opening hours</h3>
						<dl>
							{workTime.map((item) => (
								<div key={item.day}>
									<dt>
										<strong>{item.day}:</strong>
									</dt>
									<dd>{item.hours}</dd>
								</div>
							))}
						</dl>
					</article>
					<section className='experts__mobile-flex'>
						<TimelyButton
							classNames='experts-button'
							html={
								'<script>new timelyButton("joshwoodcolour", {"style":"dark"});</script>'
							}
						/>
						<Link
							to='/pages/salon#map'
							className='experts-button experts-button--contact'
						>
							<span>contact us</span>
						</Link>
					</section>
				</section>
				<section>
					<Filter list={filtersList} type='blog' isExperts />
					<ExpertsGrid experts={experts} />
				</section>
			</section>
		</section>
	);
};

export default ExpertsPage;
