import React, { createContext, useEffect, useState, useMemo } from 'react';
import Cookies from 'js-cookie';
import { graphql, useStaticQuery } from 'gatsby';
import StoryblokClient from 'storyblok-js-client';
import littledata from '@littledata/headless-shopify-sdk';
import {
	decodeBase64,
	gemMiracleTone,
	isBrowser,
	sendAddToBagEvent,
	sendRemoveCartEvent,
} from '../utils/helpers';
import createCart from '../utils/cart/create';
import getCart from '../utils/cart/get';
import addToCart from '../utils/cart/addItem';
import updateCartLineItem from '../utils/cart/updateItem';
import removeFromCart from '../utils/cart/removeItem';
import updateCartAttributes from '../utils/cart/attributesUpdate';
import { submitErrorToSlack } from '../utils/slackMessage';
import getProductStorefrontId from '../helpers/getProductStorefrontid';
import getLittledataCartAttributes from '../helpers/getLittledataCartAtrributes';
import fetchLittledataClientIds from '../utils/littledataClient';

const menu = require('../../static/menu.json');
const { getFeatureFlag } = require('../utils/node-helpers');
const ratings = require('../../static/ratings.json');

const defaultValues = {
	filterTags: [],
	filterList: [],
	loading: false,
	menu,
	variantSelected: null,
	setNotificationText: () => { },
	setCartBlocked: () => { },
	setSlideProperties: () => { },
	showModal: () => { },
	updateFilterTag: () => { },
	updateSortKey: () => { },
	resetFilters: () => { },
	resetSorts: () => { },
	setFilterList: () => { },
	setModal: () => { },
	onClose: () => { },
	addVariantToCart: () => { },
	replaceCartItem: () => { },
	addMultiVariantsToCart: () => { },
	removeLineItem: () => { },
	updateLineItem: () => { },
	updateCart: () => { },
	closeShotModal: () => { },
	openCart: () => { },
	modal: {},
	notificationText: '',
	activeOverlay: false,
	activeCartOverlay: false,
	isOpen: false,
	cart: {
		lines: {
			edges: [],
		},
	},
	upsellModalWasOpened: false,
	storyBlokImages: [],
};

const query = graphql`
	{
		mobileMenuEntry: storyblokEntry(full_slug: { eq: "mobile-menu" }) {
			content
			name
			full_slug
			uuid
			id
			internalId
		}
		mobileMenuSecondEntry: storyblokEntry(
			full_slug: { eq: "mobile-menu-secondary" }
		) {
			content
			name
			full_slug
			uuid
			id
			internalId
		}
		cartEntry: storyblokEntry(full_slug: { eq: "cart" }) {
			content
			name
			full_slug
			uuid
			id
			internalId
		}
		cartModal: storyblokEntry(full_slug: { eq: "test-new-modal" }) {
			content
			name
			full_slug
			uuid
			id
			internalId
		}
		faqEntry: storyblokEntry(full_slug: { eq: "faq" }) {
			content
			name
			full_slug
			uuid
			id
			internalId
		}
		allFile {
			edges {
				node {
					base
					childImageSharp {
						gatsbyImageData(placeholder: BLURRED)
					}
					publicURL
				}
			}
		}
		allShopifyCollection {
			nodes {
				handle
				title
				metafields {
					key
					namespace
					value
				}
			}
		}
		allShopifyProduct {
			nodes {
				id
				handle
				collections {
					handle
				}
				tags
				title
				storefrontId
				productType
				descriptionHtml
				options {
					name
					values
					shopifyId
				}
				priceRangeV2 {
					maxVariantPrice {
						amount
						currencyCode
					}
					minVariantPrice {
						amount
						currencyCode
					}
				}
				metafields {
					key
					value
				}
				media {
					preview {
						image {
							altText
							originalSrc
							gatsbyImageData(width: 600)
						}
					}
					shopifyId
				}
				variants {
					availableForSale
					storefrontId
					compareAtPrice
					image {
						originalSrc
						gatsbyImageData
					}
					metafields {
						key
						value
					}
					price
					title
					product {
						title
						handle
						media {
							preview {
								image {
									originalSrc
									altText
									gatsbyImageData
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const StoreContext = createContext(defaultValues);

const cartLocalStorageKey = 'shopify_cart_id';

export const StoreProvider = ({ children }) => {
	const sbClient = new StoryblokClient({
		accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
	});

	const queryData = useStaticQuery(query);
	const [addShadeShotFromCart, setAddShadeShotFromCart] = useState(false);
	const [cart, setCart] = useState(defaultValues.cart);
	const [allProducts, setAllProducts] = useState(
		queryData.allShopifyProduct.nodes
	);
	const [allCollections, setAllCollections] = useState(
		queryData.allShopifyCollection.nodes
	);
	const [mobileMenuPrimary, setMobileMenuPrimary] = useState(
		queryData.mobileMenuEntry
	);
	const [mobileMenuSecondary, setMobileMenuSecondary] = useState(
		queryData.mobileMenuSecondEntry
	);
	const [cartStories, setCartStories] = useState(queryData.cartModal);
	const [faqEntry, setFaqEntry] = useState(queryData.faqEntry);
	const [loading, setLoading] = useState(false);
	const [isItemAddedToBag, setIsItemAddedToBag] = useState(false);
	const [filterTags, setFilterTags] = useState([]);
	const [sortKeys, setSortKeys] = useState([]);
	const [filterList, setNewFilterList] = useState([]);
	const [variantSelected, setVariantSelected] = useState(null);
	const [modal, setNewModal] = useState(null);
	const [activeModal, setActiveModal] = useState(false);
	const [activeOverlay, setActiveOverlay] = useState(false);
	const [activeCartOverlay, setActiveCartOverlay] = useState(false);
	const [notificationText, setNewNotificationText] = useState('');
	const [isOpen, setOpen] = useState(false);
	const [showBundle, setShowBundle] = useState(
		isBrowser && window.innerWidth >= 991
	);
	const [globalMetafields, setGlobalMetafields] = useState(null);
	const [checkoutModalText, setCheckoutModalText] = useState('');
	const [checkoutModalTitle, setCheckoutModalTitle] = useState('');
	const [checkoutModalProduct, setCheckoutModalProduct] = useState(null);
	const [token, setToken] = useState(null);
	const [tokenCustomer, setTokenCustomer] = useState(null);
	const [isSteps, setIsSteps] = useState(false);
	const [isShowGallery, setIsShowGallery] = useState(false);
	const [miracleGallerySwiper, setMiracleGallerySwiper] = useState(null);
	const [miracleGalleryImages, setNewMiracleGalleryImages] = useState(null);
	const [plpRecommended, setPlpRecommended] = useState(null);
	const [plpTrending, setPlpTrending] = useState(null);
	const [modalText, setModalText] = useState('');
	const [collections, setCollections] = useState([]);
	const [isOpenSubmenu, setIsOpenSubmenu] = useState(false);
	const [bundleProducts, setBundleProducts] = useState([]);
	const [activeBundleId, setNewActiveBundleId] = useState([]);
	const [shadeModal, setShadeModal] = useState(false);
	const [upsellModalWasOpened, setUpsellModalWasOpened] = useState(false);
	const [currentStepPDP, setCurrentStepPDP] = useState(1);
	const [selectedPermanentBase, setSelectedPermanentBase] = useState({});
	const [selectedPermanentShot, setSelectedPermanentShot] = useState({});
	const [isCartBlocked, setIsCartBlocked] = useState(false);
	const [yotpoRatings, setYotpoRatings] = useState([]);
	const [subscriptionsEnabled, setSubscriptionsOn] = useState(
		getFeatureFlag(process.env.GATSBY_FEATURE_SUBSCRIPTIONS, 'true')
	);
	const [storyBlokImages, setStoryBlokImages] = useState(
		queryData.allFile.edges
	);
	const [customerData, setCustomerData] = useState(null);
	const [articles, setArticles] = useState([]);
	const [isProductInfoButtonVisible, setIsProductInfoButtonVisible] = useState(false);
	useEffect(() => {
		setYotpoRatings(ratings);
	}, []);
	useEffect(() => {
		const metafields = JSON.parse(isBrowser && localStorage.getItem('ShopMetafields') || '[]');
		setGlobalMetafields(metafields);
	}, []);
	useEffect(() => {
		const tokenCustomer = sessionStorage.getItem('tokenCustomer');
		setTokenCustomer(tokenCustomer || null);
	}, []);

	const setMiracleGalleryImages = (name, type) => {
		if (type !== 'tones') {
			name = name.replace(/[^.0-9]/gim, '');
		}
		const mainPath = `miracle/steps/gallery/${type}`,
			images = [];
		for (let i = 1; i <= 3; i++) {
			const obj = {
				name:
					type !== 'tones'
						? `${name}-${i}`
						: `${gemMiracleTone(name).toneImg}-${gemMiracleTone(name).colourName
						}-${i}`,
				directory: mainPath,
			};
			images.push(obj);
		}
		setNewMiracleGalleryImages(images);
	};
	const setActiveBundleId = (id) => {
		setNewActiveBundleId(activeBundleId === id ? null : id);
	};
	const openShotModal = () => {
		setActiveOverlay(true);
		setShadeModal(true);
	};
	const closeShotModal = () => {
		setActiveOverlay(false);
		setShadeModal(false);
	};
	const openMenu = () => {
		setIsOpenSubmenu(true);
	};
	const closeMenu = () => {
		setIsOpenSubmenu(false);
	};
	const resetArray = () => {
		setPlpRecommended([]);
		setPlpTrending([]);
	};
	const updateTrending = (idx) => {
		setPlpTrending([...plpTrending, idx]);
	};
	const setAuthorized = (token, email = '', tokenCustomer = null) => {
		setToken(token);
		setTokenCustomer(tokenCustomer);
		sessionStorage.setItem('accessToken', token);
		sessionStorage.setItem('userEmail', email);
	};
	const removeAuthorized = () => {
		sessionStorage.removeItem('accessToken');
		sessionStorage.removeItem('userEmail');
		sessionStorage.removeItem('is_logged_in_password_less');
		sessionStorage.removeItem('refresh_token');
		sessionStorage.removeItem('id_token');
		sessionStorage.removeItem('access_token');
		sessionStorage.removeItem('expires_in');
		sessionStorage.removeItem('tokenCustomer');
		setToken(null);
		setTokenCustomer(null);
	};
	const updateRecommended = (idx) => {
		setPlpRecommended([...plpRecommended, idx]);
	};
	const setCartItem = (cartData) => {
		if (isBrowser && cartData.id) {
			localStorage.setItem(cartLocalStorageKey, cartData.id);
		}

		setCart(cartData);
		return cartData;
	};
	const createAndSetCart = () => {
		createCart()
			.then(async (data) => {
				setCartItem(data.cartCreate.cart);
				return data.cartCreate.cart;
			})
			.then((cartData) => fetchLittledataClientIds(cartData))
			.catch((error) => {
				console.error('littledata Error in createAndSetCart:', error);
			});
	};
	const initializeCart = async () => {
		const existingId = isBrowser
			? localStorage.getItem(cartLocalStorageKey)
			: null;

		if (existingId && existingId !== 'null') {
			try {
				getCart(existingId).then((data) => {
					if (data && data.cart) {
						setCartItem(data.cart);
					} else createAndSetCart();
				});
			} catch (e) {
				createAndSetCart();
			}
		} else createAndSetCart();
	};
	const shipScoutTrackAddToCart = () => {
		if (typeof window.ShipScoutTrackAddToCart === 'function' && isBrowser) {
			window.ShipScoutTrackAddToCart();
		}
	};

	if (!cart || !cart.id) {
		initializeCart();
	}
	const addVariantToCart = async (
		variantId,
		quantity = 1,
		customAttributesArray = [],
		toCheckout = false,
		product = null,
		sellingPlanId = null,
		cartOpen = true,
		note = null
	) => {
		if (!variantId || !quantity) {
			console.error('Both a size and quantity are required.');
			return;
		}
		setLoading(true);
		const cartId = cart.id ?? localStorage.getItem('shopify_cart_id');

		const userId = Cookies.get('_sc_uuid');
		const testNumber = Cookies.get('_sc_test');
		const testVariant = Cookies.get(`_sc_${testNumber}`);

		let valueShipScout = `${userId}_${testNumber}_${testVariant}`;
		if (typeof window.ShipScoutLineItemProperty !== 'undefined') {
			valueShipScout = window.ShipScoutLineItemProperty;
		}
		const shipscoutAttribute = [
			{
				key: '_sc',
				value: valueShipScout,
			},
		];
		const customAttributes = [
			...customAttributesArray,
			...shipscoutAttribute,
		];

		const lineItems = [
			{
				merchandiseId: variantId,
				quantity,
				attributes: customAttributes,
			},
		];

		if (sellingPlanId) lineItems[0].sellingPlanId = btoa(sellingPlanId);

		return addToCart(cartId, lineItems)
			.then(async (data) => {
				const newCart = data?.cartLinesAdd?.cart;
				if (!newCart && newCart === null) {
					console.error(
						'addToCart - data?.cartLinesAdd is null',
						data?.cartLinesAdd
					);
					throw new Error(
						`Error: Failed to add variant to cart. Returned Cart is null. Make sure the product "${product?.title
						}" has status ACTIVE and is published on "Space 48 - Gatsby Cloud Production". Product URL: ${getProductStorefrontId(
							product
						)}. Data: [Product]: { title: ${product?.title
						}, handle: ${product?.handle}, productType: ${product?.productType
						} }, [Cart]: { id: ${newCart?.id
						}, cost: ${JSON.stringify(
							newCart?.cost,
							null,
							' '
						)},  }`
					);
				}
				if (!newCart && newCart === undefined) {
					console.error(
						'addToCart - data?.cartLinesAdd is undefined',
						data?.cartLinesAdd
					);
					throw new Error(
						`Error: Failed to add variant to cart. Returned Cart is undefined. Product URL: ${getProductStorefrontId(
							product
						)}. Data: [Product]: { id: ${product?.id}, title: ${product?.title
						}, handle: ${product?.handle}, productType: ${product?.productType
						}, metafields: ${product?.metafields?.length
						}, storefrontId: ${product?.storefrontId
						}, totalInventory: ${product?.totalInventory}, tags: ${product?.tags?.length
						} }, [Cart]: { id: ${newCart?.id
						}, cost: ${JSON.stringify(
							newCart?.cost,
							null,
							' '
						)}, createdAt: ${newCart?.createdAt}, lines: ${newCart?.lines?.edges
						}, checkoutUrl: ${newCart?.checkoutUrl}  }`
					);
				}
				shipScoutTrackAddToCart();
				setCart(newCart);
				setLoading(false);
				cartOpen && setOpen(true);
				cartOpen && setActiveCartOverlay(true);
				const cartAttributes = await getLittledataCartAttributes();

				if (cartAttributes) {
					const attributes = note
						? [...cartAttributes, note]
						: cartAttributes;
					await updateCartAttributes(cartId, attributes);
				}

				const item = newCart?.lines?.edges?.filter(
					(item) =>
						item?.node?.merchandise.id ===
						decodeBase64(variantId, false)
				)[0];

				await sendAddToBagEvent(
					item?.node,
					product,
					item?.node?.quantity,
					newCart?.lines?.edges
				);
				setIsItemAddedToBag(true);
				await littledata.sendCartToLittledata(cartId);
			})
			.catch((error) => {
				setIsItemAddedToBag(false);
				submitErrorToSlack(
					'Failed to add the item to the bag - addToCart',
					error,
					'POST'
				);
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateLineItem = async (cartId, item, quantity) => {
		setLoading(true);

		updateCartLineItem(
			cartId,
			item.node.id,
			item.node.merchandise.id,
			quantity
		).then((data) => {
			const isRemove = quantity === 0;
			setCart(data.cartLinesUpdate.cart);

			setLoading(false);
			setOpen(true);
			setActiveCartOverlay(true);

			if (isRemove) {
				sendRemoveCartEvent(item);
			} else {
				sendAddToBagEvent(item.node, null, quantity, item);
			}
		});
	};

	const removeLineItem = (cartId, item, itemId, title) => {
		setLoading(true);

		return removeFromCart(cartId, [itemId]).then((data) => {
			setCart(data.cartLinesRemove.cart);
			sendRemoveCartEvent(item.node ?? item, title);
		});
	};
	const removeAllLineItems = async () => {
		if (!cart.lines.edges.length) return;
		setLoading(true);

		const lineItemIds = cart.lines.edges.map((item) => item.node.id);
		return removeFromCart(cart.id, lineItemIds).then((data) => {
			setCart(defaultValues.cart);
		});
	};
	const updateCart = (newCart) => {
		setCart(newCart);
	};

	const openCart = () => {
		setOpen(true);
		setActiveCartOverlay(true);
	};
	const closeCart = () => {
		setOpen(false);
		setActiveCartOverlay(false);
	};
	const updateFilterTag = (tag) => {
		const isEql = filterTags.includes(tag),
			newFilterTags = isEql
				? filterTags.filter((item) => item !== tag)
				: [...filterTags, tag];
		setFilterTags(newFilterTags);
	};
	const updateSortKey = (key) => {
		const splitKey = key.split('-'),
			negativKey =
				splitKey[1] === 'asc'
					? `${splitKey[0]}-desc`
					: `${splitKey[0]}-asc`,
			isNegativKey = sortKeys.includes(negativKey),
			isKey = sortKeys.includes(key),
			newSortKeys =
				!isKey && !isNegativKey
					? [...sortKeys, key]
					: isKey
						? sortKeys.filter((item) => item !== key)
						: [...sortKeys.filter((item) => item !== negativKey), key];
		setSortKeys(newSortKeys);
	};
	const setFilterList = (list = []) => {
		list = list.filter(
			(i) =>
				i.indexOf('YG') === -1 ||
				i.indexOf('klaviyo') === -1 ||
				i.indexOf('winner') === -1 ||
				i.indexOf('yblocklist') === -1 ||
				i.indexOf('small') === -1 ||
				i.indexOf('gift card') === -1 ||
				i.indexOf('naturals') === -1 ||
				i !== 'yblocklist'
		);
		list = list.map((i) => i.toLowerCase());
		const newFilter = Array.from(new Set(filterList.concat(list)));
		setNewFilterList(newFilter);
	};
	const resetFilters = () => {
		setNewFilterList([]);
		setFilterTags([]);
	};
	const resetSorts = () => {
		setSortKeys([]);
	};
	const setSlideProperties = (variant) => {
		setVariantSelected(variant);
	};
	const setModal = (product, type, text = '', info = null) => {
		setNewModal({
			product,
			type,
			text,
			info,
		});
	};
	const showModal = (bool) => {
		setActiveModal(bool);
		setActiveOverlay(bool);
	};
	const setNotificationText = (notificationText) => {
		setNewNotificationText(notificationText);
	};
	const setCartBlocked = (isBlocked) => {
		setIsCartBlocked(isBlocked);
	};
	const addMultiVariantsToCart = (lineItemsToUpdate) => {
		if (lineItemsToUpdate.length === 0) {
			console.error('Products are required.');
			return;
		}
		setLoading(true);
		const cartId = cart.id ?? localStorage.getItem('shopify_cart_id');
		const lines = lineItemsToUpdate.map((item) => {
			const line = {
				merchandiseId: item.variantId,
				quantity: 1,
				attributes: item.attributes,
			};
			if (item.sellingPlanId) {
				line.sellingPlanId = item.sellingPlanId;
			}
			return line;
		});

		return addToCart(cartId, lines)
			.then(async (data) => {
				const newCart = data?.cartLinesAdd?.cart;
				if (!newCart) {
					console.error(
						'addToCart - There is no cart in the response.',
						data
					);
					throw new Error(
						`API request failed in function addVariantToCart-addToCart- There is no cart in the response.`
					);
				}
				setCart(newCart);
				setActiveCartOverlay(true);
				setOpen(true);
			})
			.catch((error) => {
				submitErrorToSlack(
					'Failed to add the multiple items to the bag (addMultiVariantsToCart)- addToCart',
					error,
					'POST'
				);
				console.error(error);
			})
			.finally(() => {
				setLoading(false);
			});
	};
	const replaceCartItem = (
		cartId,
		item,
		variantForCheck,
		oldVariantId,
		newVariantId,
		attrs,
		quantity = 1,
		sellingPlanId = null
	) => {
		if (variantForCheck !== newVariantId || sellingPlanId !== null) {
			setCartBlocked(true);
			addVariantToCart(
				newVariantId,
				quantity,
				attrs,
				false,
				null,
				sellingPlanId
			)
				.then(() => removeLineItem(cartId, item, oldVariantId))
				.then(() => setCartBlocked(false));
		} else {
			addVariantToCart(
				newVariantId,
				quantity,
				attrs,
				false,
				null,
				sellingPlanId
			);
		}
	};
	const value = useMemo(
		() => ({
			...defaultValues,
			addShadeShotFromCart,
			setAddShadeShotFromCart,
			sbClient,
			mobileMenuPrimary,
			setMobileMenuPrimary,
			mobileMenuSecondary,
			setMobileMenuSecondary,
			cartStories,
			setCartStories,
			faqEntry,
			setFaqEntry,
			addVariantToCart,
			cart,
			setCart,
			removeLineItem,
			removeAllLineItems,
			updateCart,
			updateLineItem,
			updateFilterTag,
			updateSortKey,
			setFilterList,
			resetFilters,
			resetSorts,
			setSlideProperties,
			setNotificationText,
			setIsItemAddedToBag,
			isItemAddedToBag,
			setModal,
			showModal,
			openCart,
			closeCart,
			addMultiVariantsToCart,
			replaceCartItem,
			checkoutModalText,
			setCheckoutModalText,
			setCheckoutModalTitle,
			checkoutModalTitle,
			setCheckoutModalProduct,
			checkoutModalProduct,
			setShowBundle,
			showBundle,
			sortKeys,
			activeModal,
			activeOverlay,
			variantSelected,
			modal,
			notificationText,
			filterTags,
			filterList,
			loading,
			token,
			tokenCustomer,
			setAuthorized,
			removeAuthorized,
			isSteps,
			activeCartOverlay,
			setIsSteps,
			isShowGallery,
			setIsShowGallery,
			miracleGallerySwiper,
			setMiracleGallerySwiper,
			miracleGalleryImages,
			setMiracleGalleryImages,
			plpRecommended,
			updateRecommended,
			setModalText,
			modalText,
			updateTrending,
			plpTrending,
			collections,
			setCollections,
			resetArray,
			isOpenSubmenu,
			isOpen,
			openMenu,
			closeMenu,
			bundleProducts,
			setBundleProducts,
			activeBundleId,
			setActiveBundleId,
			shadeModal,
			openShotModal,
			closeShotModal,
			upsellModalWasOpened,
			setUpsellModalWasOpened,
			setCurrentStepPDP,
			currentStepPDP,
			selectedPermanentBase,
			setSelectedPermanentBase,
			selectedPermanentShot,
			setSelectedPermanentShot,
			isCartBlocked,
			yotpoRatings,
			setYotpoRatings,
			allProducts,
			setAllProducts,
			allCollections,
			setAllCollections,
			subscriptionsEnabled,
			setSubscriptionsOn,
			storyBlokImages,
			setStoryBlokImages,
			customerData,
			setCustomerData,
			articles,
			setArticles,
			globalMetafields,
			setGlobalMetafields,
			isProductInfoButtonVisible,
			setIsProductInfoButtonVisible,
		}),
		[
			sbClient,
			cart,
			updateFilterTag,
			updateSortKey,
			resetFilters,
			resetSorts,
			showModal,
			openCart,
			closeCart,
			addMultiVariantsToCart,
			replaceCartItem,
			checkoutModalText,
			checkoutModalTitle,
			checkoutModalProduct,
			showBundle,
			sortKeys,
			activeModal,
			activeOverlay,
			variantSelected,
			modal,
			notificationText,
			filterTags,
			filterList,
			loading,
			token,
			tokenCustomer,
			removeAuthorized,
			isSteps,
			setIsItemAddedToBag,
			isItemAddedToBag,
			activeCartOverlay,
			isShowGallery,
			miracleGallerySwiper,
			miracleGalleryImages,
			plpRecommended,
			updateRecommended,
			modalText,
			updateTrending,
			plpTrending,
			collections,
			resetArray,
			isOpenSubmenu,
			isOpen,
			openMenu,
			closeMenu,
			bundleProducts,
			activeBundleId,
			setActiveBundleId,
			shadeModal,
			openShotModal,
			closeShotModal,
			upsellModalWasOpened,
			currentStepPDP,
			selectedPermanentBase,
			selectedPermanentShot,
			isCartBlocked,
			yotpoRatings,
			allProducts,
			allCollections,
			subscriptionsEnabled,
			customerData,
			setCustomerData,
			articles,
			setArticles,
			globalMetafields,
			setGlobalMetafields,
			isProductInfoButtonVisible,
			setIsProductInfoButtonVisible,
		]
	);

	return (
		<StoreContext.Provider value={value}>{children}</StoreContext.Provider>
	);
};
