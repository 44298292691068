// External
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Thumbs } from 'swiper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { GatsbyImage } from 'gatsby-plugin-image';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, Thumbs]);

const ProductPhotoGallery = ({
	product,
	thumbsSwiper,
	setThumbsSwiper,
	customPreviewImage,
	mainSwiper
}) => {
	const countThumb = (product?.media || []).length;

	const goNext = () => {
		if (thumbsSwiper && mainSwiper) {
			thumbsSwiper.slideNext();
			mainSwiper.slideNext();
		}
	};
	const goPrev = () => {
		if (thumbsSwiper && mainSwiper) {
			thumbsSwiper.slidePrev();
			mainSwiper.slidePrev();
		}
	};

	return (
		<nav className='pdp-photo-gallery' aria-label='Product photo gallery'>
			{countThumb > 5 && (
				<button
					type='button'
					className='pdp-prev-arrow'
					onClick={goPrev}
					aria-label='Previous photos'
				/>
			)}
			<Swiper
				loop={false}
				onSwiper={(swiper) => setThumbsSwiper(swiper)}
				slidesPerView={5}
				spaceBetween={10}
				breakpoints={{
					1200: {
						spaceBetween: 12,
					},
				}}
				preloadImages={false}
				lazy
				slidesPerGroup={5}
			>
				{customPreviewImage && (
					<SwiperSlide
						className='pdp-photo-gallery__slide'
						key={customPreviewImage}
					>
						<figure className='pdp-photo-gallery__item'>
							<LazyLoadImage
								src={customPreviewImage}
								alt={product?.title}
								className='pdp-photo-gallery__img swiper-lazy'
								effect='blur'
								loading='eager'
							/>
						</figure>
					</SwiperSlide>
				)}
				{Array.isArray(product?.media) &&
					product.media.map((image, index) => (
						<SwiperSlide
							className='pdp-photo-gallery__slide'
							key={`${index + 1}`}
						>
							{image && (
								<figure className='pdp-photo-gallery__item'>
									{image?.preview?.image?.gatsbyImageData &&
										!image?.preview.image.originalSrc?.includes(
											'.gif'
										) ? (
										<GatsbyImage
											className='swiper-lazy'
											alt={`${product.title} thumbnail`}
											image={
												image.preview.image
													.gatsbyImageData
											}
											loading={
												index === 0 ? 'eager' : 'lazy'
											}
										/>
									) : (
										<LazyLoadImage
											className='swiper-lazy'
											src={
												image.preview.image.originalSrc
											}
											alt={`${product.title} thumbnail`}
											effect='blur'
											loading='lazy'
										/>
									)}
								</figure>
							)}
						</SwiperSlide>
					))}
			</Swiper>
			{countThumb > 5 && (
				<button
					type='button'
					className='pdp-next-arrow'
					onClick={goNext}
					aria-label='Next photos'
				/>
			)}
		</nav>
	);
};

export default ProductPhotoGallery;
