// External
import React, { useContext, useEffect, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Thumbs, Navigation } from 'swiper';
import cn from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Internal
import LocalImage from '../../../../components/LocalImage';
import { useWindowWidth } from '../../../../utils/hooks';
import { StoreContext } from '../../../../context/store-context';

SwiperCore.use([Thumbs, Navigation]);

const ProductPhotoSlideContainer = ({
	product,
	thumbsSwiper,
	customPreviewImage,
	setSliderNoAnimation,
	setMainSwiper
}) => {
	const { variantSelected, setSlideProperties, setModal, showModal } =
		useContext(StoreContext);
	const windowWidth = useWindowWidth();

	const [swiper, setSwiper] = useState(null);

	const imagesUrls =
		(product?.media || []).length &&
		product?.media.map((item, index) => ({
			url: `${index + 1}`,
			originalSrc: item.preview.image.originalSrc,
			index,
		}));

	const handleClick = (index) => {
		if (windowWidth < 750) {
			setModal(null, 'zoom-images-mobile', null, {
				product,
				customPreviewImage,
				index,
			});
			showModal(true);
		}
	};

	useEffect(() => {
		let currentSlide = null;

		if (
			imagesUrls &&
			imagesUrls.length &&
			swiper &&
			variantSelected &&
			variantSelected?.image
		) {
			const variantTitle = variantSelected.title;
			const transformedVariantTitle = !variantSelected.variantSelectImage
				? typeof variantTitle === 'string' &&
				variantTitle.toLowerCase().replaceAll(' ', '-')
				: variantSelected.variantSelectImage;

			currentSlide = imagesUrls.filter((item) =>
				item.originalSrc.includes(transformedVariantTitle)
			)[0];

			!currentSlide
				? (currentSlide = imagesUrls.filter(
					(item) =>
						item.originalSrc &&
						item.originalSrc.includes(
							variantSelected.image.originalSrc
						)
				)[0])
				: currentSlide;

			setSliderNoAnimation(true);

			currentSlide &&
				Number.isInteger(currentSlide?.index) &&
				swiper.slideTo(currentSlide.index);
			setTimeout(() => {
				setSliderNoAnimation(false);
			}, 100);
		}
	}, [variantSelected, setSlideProperties, swiper]);

	return (
		<>
			{Array.isArray(product?.media) && product.media.length ? (
				<Swiper
					onSwiper={(swiper) => {
						setSwiper(swiper);
						setMainSwiper(swiper);
					}}
					slidesPerView={windowWidth > 749 ? 1 : 2}
					simulateTouch='false'
					centeredSlides='false'
					spaceBetween={10}
					className='gallery-top'
					thumbs={{ swiper: thumbsSwiper }}
					breakpoints={{
						750: { spaceBetween: 0 },
					}}
					preloadImages={false}
					lazy
					initialSlide={0}
				>
					{customPreviewImage && (
						<SwiperSlide
							className={cn('pdp-photo-slider__item', {
								'pdp-photo-slider__item--center':
									(product?.media || []).length === 1,
							})}
							key={customPreviewImage}
						>
							<figure
								id='pdpPhotoSliderImg'
								className='pdp-photo-slider__img'
							>
								{customPreviewImage.gatsbyImageData ? (
									<GatsbyImage
										className='swiper-lazy'
										alt={product.title}
										loading='eager'
										image={getImage(
											customPreviewImage.gatsbyImageData
										)}
									/>
								) : (
									<LazyLoadImage
										className='swiper-lazy'
										src={customPreviewImage.originalSrc}
										alt={product.title}
										effect='blur'
										loading='lazy'
									/>
								)}
							</figure>
						</SwiperSlide>
					)}
					{Array.isArray(product?.media) &&
						product.media.map((image, index) => (
							<SwiperSlide
								className={cn('pdp-photo-slider__item', {
									'pdp-photo-slider__item--center':
										(product?.media || []).length === 1,
								})}
								key={`${image.shopifyId}-${index + 1}`}
								onClick={
									windowWidth < 750
										? () => handleClick(index)
										: null
								}
							>
								<figure
									id='pdpPhotoSliderImg'
									className='pdp-photo-slider__img'
								>
									{image?.preview?.image?.gatsbyImageData &&
										!image?.preview.image.originalSrc?.includes(
											'.gif'
										) ? (
										<GatsbyImage
											className='swiper-lazy'
											loading={
												index === 0 ? 'eager' : 'lazy'
											}
											alt={`${product.title} - Image ${index + 1
												}`}
											image={
												image.preview.image
													.gatsbyImageData
											}
											placeholder='blurred'
											priority={index === 0}
											fetchpriority={
												index === 0 ? 'high' : 'low'
											}
											backgroundColor='white'
										/>
									) : (
										<LazyLoadImage
											className='swiper-lazy'
											src={
												image.preview.image.originalSrc
											}
											alt={`${product.title} - Image ${index + 1
												}`}
											effect='blur'
											loading='lazy'
										/>
									)}
								</figure>
							</SwiperSlide>
						))}
				</Swiper>
			) : (
				<LocalImage image='no-image.jpg' alt={product?.title} />
			)}

			{windowWidth < 750 && (
				<button
					type='button'
					className='pdp-button-zoom'
					onClick={() => handleClick(0)}
					aria-label='Zoom image'
				>
					<LazyLoadImage
						src='/images/lupe-zoom-in.svg'
						alt='Zoom icon'
						effect='blur'
						loading='lazy'
					/>
				</button>
			)}
		</>
	);
};

export default ProductPhotoSlideContainer;
