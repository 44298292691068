import React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';

import './ButtonGradient.scss';

const ButtonGradient = (props) => {
	const buttonClases = [
		{
			'btn-gradient--image': props.isImageGradient,
			'btn-gradient--outline-white': props.isOutlineWhite,
			'btn-gradient--outline-image': props.isOutlineImage,
			'btn-gradient--pink': props.isPink,
			'btn-gradient--outline-pink': props.isPinkOutline,
			'btn-gradient--pink--full-width': props.isPinkFullWidth,
			'btn-gradient--border-gradient': props.isBorderGradient,
		},
	];

	const buttonText = <span>{props.buttonText}</span>;

	if (props.isLink) {
		return (
			<Link
				to={props.linkUrl}
				className={cn('btn-gradient', buttonClases, props.className)}
				id={props.id}
			>
				{buttonText}
			</Link>
		);
	}

	return (
		<button
			className={cn('btn-gradient', buttonClases, props.className)}
			onClick={props.handleClick}
			id={props.id}
		>
			{buttonText}
		</button>
	);
};

export default ButtonGradient;
