export default [
  {
    label: "All",
    value: "all"
  },
  {
    label: "Permanent Colour",
    value: "permanent-colour"
  },
  {
    label: "Root Touch-Up",
    value: "root-touch-up"
  },
  {
    label: "Gloss Refresh",
    value: "gloss-refresh"
  },
  {
    label: "Haircare Hacks",
    value: "haircare-hacks"
  },
  {
    label: "Expert Tips",
    value: "expert-tips"
  }
];
