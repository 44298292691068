import React from 'react';
import ButtonOutStock from './ButtonOutStock';



import './Button.scss';

const OutStockData = ({
	isPdp,
	product,
	isLink,
	isPlp = false,
	plpVersion = '1',
	isVariant = false,
	textOutStock = 'Colour out of stock',
}) => (
	<>
		{isPdp && !isVariant ? (
			<ButtonOutStock
				product={product}
				isPlp={isPlp}
				plpVersion={plpVersion}
			/>
		) : (
			<>
				{!isLink && (
					<div
						className={
							isVariant ? 'button-stock variant-out-of-stock' : ''
						}
					>
						{!isVariant ? (
							<>Out of stock</>
						) : (
							<>{textOutStock}</>
						)}
					</div>
				)}
			</>
		)}
	</>
);

export default OutStockData;
