// External
import React from 'react';
import StarRating from 'react-svg-star-rating';

// Internal
import './RatingStars.scss';

const RatingStars = ({
	rating,
	count,
	showText = false,
	simplifiedRating = false,
	showNumberReviews = false,
	hideRatingText = false,
}) => (
	<div className='rating-stars'>
		<div
			className={`stars ${simplifiedRating && 'rating-stars-simplified'}`}
		>
			<StarRating
				starClassName='rating-star'
				containerClassName='stars-container'
				initialRating={rating}
				roundedCorner='false'
				activeColor='#000'
				emptyColor='#fff'
				isReadOnly='true'
				unit='float'
				count={simplifiedRating ? 1 : 5}
			/>
		</div>
		{!hideRatingText && (
			<div className='rating-text'>
				{simplifiedRating ? (
					<p>
						{rating?.toFixed(1) ?? 0}{' '}
						{showNumberReviews && `(${count ?? 0} reviews)`}
					</p>
				) : (
					<p>
						{count} {showText && 'reviews'}
					</p>
				)}
			</div>
		)}
	</div>
);

export default RatingStars;
