import React, { useRef, useState } from 'react';

import { render } from 'storyblok-rich-text-react-renderer';

const FaqQuestion = ({ question, isQuestionOpen, onToggle }) => {
  const transitionRef = useRef(null);

  const [isAnimating, setAnimating] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const toggle = () => {
    onToggle();
    if (isOpen) {
      setAnimating(true);
      setOpen(false);
      setTimeout(() => {
        setAnimating(false);
      }, 0);
    } else {
      setAnimating(true);
      setTimeout(() => {
        setAnimating(false);
        setOpen(true);
      }, 320);
    }
  }

  return (
    <li
      className={`faq-questions__question ${isQuestionOpen ? 'is-open' : ''}`}
      itemScope
      itemProp='mainEntity'
      itemType='https://schema.org/Question' key={question?._uid}>
      <button
        className='faq-questions__question-toggle'
        id={question?.url_id}
        onClick={toggle}
      >
        <h3 className='faq-questions__question-title' itemProp='name'>
          {question?.question}
        </h3>
      </button>

      <div
        ref={transitionRef}
        className='faq-questions__answer'
        itemScope
        itemProp='acceptedAnswer'
        itemType='https://schema.org/Answer'
      >
        <div className='rich-text' itemProp='text'>
          {render(question?.answer)}
        </div>
      </div>
    </li>
  )
}

export default FaqQuestion;