import React from 'react';
import NavLink from './NavLink';
import NavDropdown from './NavDropdown';
import './MobileDropdown.scss';

const MobileMenu = ({ menu, isMenuOpened, setMenuOpen }) => (
	<ul className='mobile-menu'>
		{(menu?.content?.items || []).map((blok) => (
			<li className='mobile-menu__item' key={`topLevel-${blok._uid}`}>
				{Array.isArray(blok?.children) && blok?.children?.length ? (
					<NavDropdown
						blok={blok}
						setMenuOpen={setMenuOpen}
						isMenuOpened={isMenuOpened}
					/>
				) : (
					<NavLink blok={blok} setMenuOpen={setMenuOpen} isTopLevel />
				)}
			</li>
		))}
	</ul>
);

export default MobileMenu;
