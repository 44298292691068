import React, { useContext, useEffect, useState } from 'react';

import { StoreContext } from '../../context/store-context';
import { getSellingPlanGroup } from '../../utils/node-helpers';
import { getMetafield, isAvailable, isShadeShot } from '../../utils/helpers';
import ProductVideoItem from './ProductVideoItem';

const ProductVideoContainer = ({ allSellingPlanGroups, product }) => {
  const { subscriptionsEnabled, cart } = useContext(StoreContext);
  const [sellingPlanGroup, setSellingPlanGroup] = useState(
    subscriptionsEnabled &&
    allSellingPlanGroups &&
    getSellingPlanGroup(
      product?.storefrontId,
      allSellingPlanGroups.nodes
    )
  );
  const [available, setAvailable] = useState(null);
  const isRecommended = !!getMetafield(product, 'set_as_recommended');
  const isShot = isShadeShot(product);

  useEffect(() => {
    if (subscriptionsEnabled && allSellingPlanGroups) {
      setSellingPlanGroup(
        getSellingPlanGroup(
          product?.storefrontId,
          allSellingPlanGroups.nodes
        )
      );
    }
  }, [allSellingPlanGroups]);

  useEffect(() => {
    setAvailable(isAvailable(product));
  }, []);

  return (
    <ProductVideoItem
      available={available}
      isShot={isShot}
      cart={cart}
      isRecommended={isRecommended}
      product={product}
      sellingPlanGroup={sellingPlanGroup}
      subscriptionsEnabled={subscriptionsEnabled}
    />
  )
}

export default ProductVideoContainer;