// External
import React, { useState } from 'react';
import SwiperCore, { Thumbs } from 'swiper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


// Internal
import ProductRedInfo from '../ProductRedInfo';
import ProductPhotoSlide from './ProductPhotoSlide';
import ProductPhotoGallery from './ProductPhotoGallery';
import PermanentSelection from '../../PermanentSelection/PermanentSelection';

import { getMetafield } from '../../../../utils/helpers';
import { useWindowWidth } from '../../../../utils/hooks';

import './ProductPhotosSlider.scss';

SwiperCore.use([Thumbs]);

const ProductPhotosSlider = ({
	allBaseColours,
	shadeShotsProducts,
	withShadeShot,
	product,
	customPreviewImage,
	miracleSystem,
	productsList,
	sellingPlanGroup,
	shots,
}) => {
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [mainSwiper, setMainSwiper] = useState(null);
	const [sliderNoAnimation, setSliderNoAnimation] = useState(false);

	const glossModalLinkText = getMetafield(product, 'gloss_modal_link_text');
	const windowWidth = useWindowWidth();
	const isMiracleSystem = product.handle === 'miracle-system';
	const showPermanentSection = windowWidth < 992 && shots.length > 0;
	return (
		<section className='pdp-photo'>
			{showPermanentSection && (
				<PermanentSelection
					{...{
						allBaseColours,
						shadeShotsProducts,
						withShadeShot,
						product,
						productsList,
						miracleSystem,
						sellingPlanGroup,
					}}
				/>
			)}
			{glossModalLinkText && (
				<ProductRedInfo
					product={product}
					glossModalLinkText={glossModalLinkText}
				/>
			)}
			<section
				className={`pdp-photo-slider ${
					sliderNoAnimation && 'pdp-photo-slider--no-animation'
				}`}
			>
				<ProductPhotoSlide
					setSliderNoAnimation={setSliderNoAnimation}
					customPreviewImage={customPreviewImage}
					product={product}
					thumbsSwiper={thumbsSwiper}
					mainSwiper={mainSwiper}
					setMainSwiper={setMainSwiper}
				/>
			</section>
			<ProductPhotoGallery
				product={product}
				customPreviewImage={customPreviewImage}
				setThumbsSwiper={setThumbsSwiper}
				thumbsSwiper={thumbsSwiper}
				mainSwiper={mainSwiper}
			/>
			{isMiracleSystem &&
				(windowWidth > 750 ? (
					<LazyLoadImage
						className='pdp-photo__promo'
						src='/images/miracle-pdp-flash-desktop.webp'
						alt='FREE GIFT'
						effect='blur'
					/>
				) : (
					<LazyLoadImage
						className='pdp-photo__promo'
						src='/images/miracle-pdp-flash-mobile.webp'
						alt='FREE GIFT'
						effect='blur'
					/>
				))}
		</section>
	);
};

export default ProductPhotosSlider;
