import { useEffect, useState, useRef } from 'react';

const onScreen = (ref) => {
	const observerRef = useRef(null);
	const [isOnScreen, setIsOnScreen] = useState(false);

	useEffect(() => {
		if (!ref || !ref.current) return;
		
		if (observerRef.current) {
			observerRef.current.disconnect();
		}

		observerRef.current = new IntersectionObserver(([entry]) =>
			setIsOnScreen(entry.isIntersecting)
		);
		const currentRef = ref.current;
		if (currentRef) {
			observerRef.current.observe(currentRef);
		}

		return () => {
			if (observerRef.current) {
				observerRef.current.disconnect();
			}
		};
	}, [ref]);

	return isOnScreen;
};

export default onScreen;
