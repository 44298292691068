import React, { useContext, useState } from 'react'

import SEO from '../../components/SEO';
import Breadcrumb from '../../components/BreadCrumb/Index';
import { StoreContext } from '../../context/store-context';
import ProductVideos from '../../components/ApplicationVideos/ProductVideos';
import tagsVideos from '../../constants/tagsVideos';

import './ApplicationVideos.scss';

const ApplicationVideos = ({ location, pageContext: { page, allSellingPlanGroups, videosData } }) => {

  const { allProducts } = useContext(StoreContext);
  const [filter, setFilter] = useState(['all']);

  const handleFilter = (tag) => {
    setFilter(
      tag === 'all' ? ['all'] : [tag]
    );
  }
  const videos = videosData?.content?.items?.map((video) => ({
    title: video?.handle,
    products: video?.products?.map((product) => allProducts?.find((p) => p.handle === product.handle)),
    urlVideo: video?.urlVideo,
    description: video?.description || '',
    tags: video?.tags
  })) || [];

  const filteredVideos = filter.length > 0
    ? videos.filter((video) => video.tags.some((tag) => filter.includes(tag) || filter.includes('all')))
    : videos;

  return (
    <>
      <SEO
        page={page}
        location={location}
        title="How To Videos - Josh Wood Colour"
      />

      <section className='application-videos'>
        <div className='application-videos__container'>
          <Breadcrumb section="How To Videos" />
        </div>

        <div className='application-videos__filter-container'>
          <div className='application-videos__filter-container-left'>
            <h2>How To Videos</h2>
            <p>Explore our collection of expert-led videos covering everything from hair color and care to styling tips and tool recommendations. Learn from Josh and the team to keep your hair looking and feeling its best.</p>
          </div>
          <div className='application-videos__filter-container-right'>
            <h3>Categories</h3>
            <ul>
              {tagsVideos.map((tag) => (
                <li
                  key={tag.value}
                  // eslint-disable-next-line
                  role='button'
                  onClick={() => handleFilter(tag.value)}
                  className={`${filter.includes(tag.value) ? 'item-tag--active' : ''}`}
                >
                  {tag.label}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className='application-videos__container'>
          <ProductVideos
            videos={filteredVideos}
            allSellingPlanGroups={allSellingPlanGroups}
          />
        </div>

        {filteredVideos.length === 0 && (
          <div className='application-videos__empty'>
            <p>No videos found!</p>
          </div>
        )}
      </section>
    </>
  )
}

export default ApplicationVideos;