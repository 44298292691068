import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';

import AccountDetails from '../../../templates/account/AccountPage/AccountDetails';

import { isBrowser } from '../../../utils/helpers';
import useCustomer from '../../../utils/useCustomer';

const AccountAddresses = () => {
	const [addresses, setAddresses] = useState([]),
		[customerId, setCustomerId] = useState(null),
		{ customerData, refetch } = useCustomer();

	useEffect(() => {
		if (customerData && customerData.customer) {
			setAddresses(customerData.customer.addresses.edges);
			setCustomerId(customerData.customer.id.substr(23, 13));
		}
	}, [customerData]);

	return (
		<>
			{isBrowser ? (
				<>
					{window.sessionStorage.getItem('accessToken') ? (
						<section className='account-page'>
							<div className='container'>
								<h1>My Account</h1>
								<Link
									to='/account'
									role='link'
									className='account-page__logout'
									rel='nofollow'
								>
									Return to Account Details
								</Link>
								{addresses ? (
									<div className='account-page__addresses'>
										{addresses.map((item, index) => (
											<AccountDetails
												item={item.node}
												/* eslint-disable-next-line */
												key={index}
												customerId={customerId}
												refetch={refetch}
											/>
										))}
									</div>
								) : null}
							</div>
						</section>
					) : (
						navigate('/account/login')
					)}
				</>
			) : null}
		</>
	);
};

export default AccountAddresses;
