// External
import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

// Internal
import './ExpertsGridItem.scss';
import { getShopifyImage } from 'gatsby-source-shopify';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';

const ExpertsGridItem = ({ expert }) => {
	const [expertImage, setExpertImage] = useState(null);

	useEffect(() => {
		const imageUrl = getShopifyImage({
			image: { originalSrc: `${expert?.image_expert}` },
			width: 230,
			height: 310,
		})
		setExpertImage(imageUrl);
	}, [expert]);

	return (
		<li className='expert-item'>
			<Link to={`/pages/meet-the-experts/${expert.handle}`}>
				<figure className='expert-item__img'>
					{expert.img_url ? (
						<GatsbyImage image={expertImage} alt={expert.name} />
					) : (
						<StaticImage
							src='../../../../../static/images/empty-expert.png'
							alt='empty'
						/>
					)}
				</figure>
				<h3 className='expert-item__name'>{expert.name}</h3>
				<span className='expert-item__profession'>
					{expert.tags[0]}
				</span>
			</Link>
		</li>
	);
};
export default ExpertsGridItem;
