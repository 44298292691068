import React, { useContext, useEffect, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image';

import Button from '../Button';
import { StoreContext } from '../../context/store-context';
import { getDiscountPrice, getMetafield, getPrice } from '../../utils/helpers';
import isAWholeNumber from '../../helpers/isAWholeNumber';

const ProductVideoItem = (props) => {
  const { subscriptionsEnabled } = useContext(StoreContext);
  const [groupFound, setGroupFound] = useState(
    props.sellingPlanGroup && Object.keys(props.sellingPlanGroup).length
  );
  const [discountPrice, setDiscountPrice] = useState(
    props.product?.variants[0]?.price
  );
  const [isBundleVariant, setIsBundleVariant] = useState(
    !props.sellingPlanGroup && props.product?.handle?.includes('bundle')
  );
  const [isBundle, setIsBundle] = useState(false);
  const [priceValue, setPriceValue] = useState('');
  const isMiracle = getMetafield(props.product, 'is_miracle_system');
  const isSubscribeOnPDP = getMetafield(props.product, 'subscription_on_pdp');
  const [canSubscribe, setCanSubscribe] = useState(
    groupFound && subscriptionsEnabled && isSubscribeOnPDP
  );

  const isCustomizeAndPDP = getMetafield(
    props.product,
    'is_customize_and_pdp'
  );

  const isMiracleSystemExpert = getMetafield(
    props.product,
    'is_miracle_system_expert'
  );
  const isMiracleSystemUltimate = getMetafield(
    props.product,
    'is_miracle_system_ultimate'
  );
  const productNavigation = getMetafield(
    props.product,
    'type_of_navigation_a_b_test'
  );

  const productHref = !productNavigation
    ? `/products/${props.product?.handle}`
    : `https://joshwoodcolour.com/products/${props.product?.handle}`;

  function getPriceProduct() {
    if (isBundleVariant) {
      return props.product;
    }

    return isMiracle
      ? props.product?.variants[0]
      : props.product?.variants?.filter(
        (variant) =>
          !variant.title.toLowerCase().includes('miracle')
      )[0];
  }

  const [price, setPrice] = useState(getPriceProduct());
  useEffect(() => {
    if (props.sellingPlanGroup) {
      setGroupFound(Object.keys(props.sellingPlanGroup).length);
    }
  }, [props.sellingPlanGroup]);

  useEffect(() => {
    setCanSubscribe(groupFound && subscriptionsEnabled && isSubscribeOnPDP);
  }, [groupFound]);

  useEffect(() => {
    (props.product?.options || []).forEach((option) => {
      const name = option.name.toLowerCase();
      if (name === 'bundle') setIsBundle(true);
    });
  }, []);

  useEffect(() => {
    if (canSubscribe) {
      const plan = props.sellingPlanGroup.sellingPlans.edges[0],
        productPrice = parseInt(props.product.variants[0].price),
        discount = getDiscountPrice(
          productPrice,
          plan.node.pricingPolicies[0].adjustmentValue.percentage
        );
      setDiscountPrice(discount);
    }
  }, [canSubscribe]);

  useEffect(() => {
    setPrice(getPriceProduct());
  }, [isBundle, isMiracle]);

  useEffect(() => {
    const priceIsWholeNumber = isAWholeNumber(price?.price);
    setPriceValue(
      isSubscribeOnPDP
        ? `£${isAWholeNumber(discountPrice)
          ? parseInt(discountPrice)
          : discountPrice
        }`
        : `£${priceIsWholeNumber ? parseInt(price?.price) : price?.price}`
    );
  }, [discountPrice, price, props.product]);

  const adjustPrice = () => {
    const priceOfProduct = price?.compareAtPrice || price?.price;
    const priceIsWholeNumber = isAWholeNumber(priceOfProduct);

    return priceIsWholeNumber ? 0 : 2;
  };

  const isCrossPriceInBundles =
    (!isMiracle && !canSubscribe && 'original-product-price--cross') ||
    ((isMiracleSystemExpert || isMiracleSystemUltimate || !canSubscribe) &&
      'original-product-price--cross');

  const productPriceFrom = props?.product?.metafields?.find(
    (metafield) => metafield.key === 'price_from'
  )?.value;

  const adjustPriceFromMetafield = getPrice(
    productPriceFrom,
    props.cart,
    adjustPrice(productPriceFrom)
  );

  return (
    <>
      <div className='video-item__product-info'>
        <div className='video-item__product-info__image'>
          <GatsbyImage
            alt={props?.product?.title}
            image={
              props?.product?.media[0]?.preview?.image?.gatsbyImageData
            }
          />
        </div>
        <div className='video-item__product-info__content'>
          <div className='video-item__product-info__content-top'>
            <h5>{props?.product?.title}</h5>
            <div className='video-item__product-info__content-top-price'>
              {!props?.isShot &&
                props?.product?.variants &&
                parseInt(price.price) ? (
                <>
                  {price?.compareAtPrice ||
                    canSubscribe ? (
                    <div
                      className={`original-product-price ${isCrossPriceInBundles}`}
                    >
                      {canSubscribe ? (
                        <span className='current-product-price__text'>
                          from {priceValue}
                        </span>
                      ) : (
                        getPrice(
                          price[
                          canSubscribe
                            ? 'price'
                            : 'compareAtPrice'
                          ],
                          props?.cart,
                          adjustPrice()
                        )
                      )}
                    </div>
                  ) : null}
                  {(!isMiracle ||
                    (isMiracle && !canSubscribe)) &&
                    !canSubscribe && (
                      <p
                        className={`current-product-price ${(price.compareAtPrice ||
                          canSubscribe) &&
                          'current-product-price--red'
                          }`}
                      >
                        {productPriceFrom
                          ? `From ${adjustPriceFromMetafield}`
                          : priceValue}
                      </p>
                    )}
                </>
              ) : null}
            </div>
          </div>
          <p
            className='video-item__product-info__content-description'
            dangerouslySetInnerHTML={{
              __html: props?.product?.descriptionHtml
            }}
          />
          <Button
            buttonText={
              isMiracle ||
                isCustomizeAndPDP ||
                canSubscribe
                ? 'customise'
                : 'add to bag'
            }
            product={props?.product}
            className='product-list-item__button'
            isLink={
              isMiracle ||
              isCustomizeAndPDP ||
              canSubscribe
            }
            linkUrl={
              (isMiracle ||
                isCustomizeAndPDP ||
                canSubscribe) &&
              productHref
            }
            isDark
            isBundle={isBundle}
            availableForSale={props?.available}
            isPlp
            isApplicationVideo
            plpVersion='1'
          />
        </div>
      </div>
    </>
  )
}

export default ProductVideoItem;