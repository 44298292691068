import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { StoreContext } from '../../../context/store-context';

import { delShopSymbols, getMetafield } from '../../../utils/helpers';

import mouse from '../../../../static/images/mouse.svg';

const ProductAwardItemV2 = ({ product, award, idx }) => {
	const { setModal, showModal } = useContext(StoreContext);
	const openModal = () => {
		setModal(product, 'ingredients');
		showModal(true);
	};
	const [hasIngredients, setIsIngredients] = useState(false);

	useEffect(() => {
		setIsIngredients(!!getMetafield(product, 'ingredients'));
	}, []);	
	return (
		<>
			{(award.title ||
				award.icon ||
				award.text ||
				award.link.url ||
				award.link.text) && (
				<li className='pdp-awards-v2__item'>
					<div className='pdp-awards-v2__icon'>
						{award.icon ? (
							<LazyLoadImage
								src={award.icon}
								alt={award.title || award.name || 'SVG icon awards'}
								effect='blur'
							/>
						) : (
							<LazyLoadImage
								src={mouse}
								alt='Mouse'
								effect='blur'
							/>
						)}
					</div>
					<span className='pdp-awards-v2__title bold'>
							{award.title}
						</span>
					{award.text ? (
						<p
							className={`pdp-awards-v2__text ${
								award.text.includes('•') && 'red-last-p'
							}`}
							dangerouslySetInnerHTML={{
								__html: delShopSymbols(award.text), // ,
							}}
						/>
					) : (
						idx === 2 && (
							<p className='pdp-awards-v2__text'>
								Not sure on your shade match? Try our free
								consultation tools
							</p>
						)
					)}
					{award.link && idx !== 1 && idx !== 2 ? (
						<a
							href={award.link.url}
							className='pdp-awards-v2__link'
						>
							{award.link.text}
						</a>
					) : idx === 1 && hasIngredients && idx !== 2 ? (
						<button
							onClick={openModal}
							className='pdp-awards-v2__link'
						>
							Full list of ingredients
						</button>
					) : (
						idx === 2 && (
							<Link
								to='/miracle-steps'
								className='pdp-awards-v2__link'
								id='colour-match-button'
							>
								Find your shade
							</Link>
						)
					)}
				</li>
			)}
		</>
	);
};
export default ProductAwardItemV2;
