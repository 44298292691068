import React from 'react';
import { Link } from 'gatsby';
import SEO from '../../../components/SEO';
import './GiftCardPage.scss';

const GiftCardPage = ({ location, pageContext: { page } }) => (
	<>
		<SEO page={page} location={location} />
		<div className='gift-card-page'>
			<section className='container'>
				<article className='gift-card-page__cards'>
					<div className='gift-card-page__card card-item'>
						<div className='card-item__img card-item__img_1' role='img' aria-label='Online Gift Card Image' />
						<div className='card-item__body card-item__body_online'>
							<h1 className='card-item__title'>
								Josh Wood Colour Gift Cards
							</h1>
							<p className='card-item__text'>
								Send an instant e-gift card to redeem against
								our products online.
							</p>
							<Link
								className='card-item__link button'
								to='/products/online-gift-card'
								aria-label='Shop Online Gift Card'
							>
								Shop Online Gift Card
							</Link>
						</div>
					</div>
					<div className='gift-card-page__card card-item card-item__salon'>
						<div className='card-item__img card-item__img_2' role='img' aria-label='Salon Gift Card Image'>
							<Link
								className='card-item__link-price button'
								target='_blank'
								to='https://joshwoodcolour.com/pages/salon-price-list'
								aria-label='View Salon Price List'
							>
								Price List
							</Link>
						</div>
						<div className='card-item__body'>
							<h2 className='card-item__title'>
								Salon Gift Card
							</h2>
							<p className='card-item__text'>
								Treat someone special to our beloved salon in
								London, home to Josh and his dream team.
							</p>
							<Link
								className='card-item__link button'
								target='_blank'
								to='https://bookings.gettimely.com/joshwoodcolour/purchase?uri=https%3A%2F%2Fbook.gettimely.com%2FGiftVoucher%2FDetails%2F103197%3FisMobile%3DTrue%26giftVoucherId%3D79572%26params%3D%25253fclient-login%25253dtrue%252526giftVoucherId%25253d79572'
								aria-label='Shop Salon Gift Card'
							>
								Shop Salon Gift Card
							</Link>
						</div>
					</div>
				</article>
			</section>
		</div>
	</>
);

export default GiftCardPage;
