import React from 'react';
import { Link } from 'gatsby';
/* eslint-disable-next-line no-confusing-arrow */
const SubmenuLinkItem = ({ item }) =>
	!item.url.includes('http') && !item.url.includes('mailto') ? (
		<Link to={item.url}>{item.title}</Link>
	) : (
		<a href={item.url} target='_blank' rel='noreferrer'>{item.title}</a>
	);

export default SubmenuLinkItem;
