// External
import React, { useEffect, useState } from 'react';

// Internal
import LevelListItem from './LevelListItem';
import ProductSelectionLevelHeader from './ProductSelectionLevelHeader';
import { getMetafield } from '../../../../../utils/helpers';
import ProductSelectionLevelFilters from './ProductSelectionLevelFilters';

const ProductSelectionLevelOne = ({
	activeStep = false,
	nextStep = false,
	isMobile = false,
	isFilterActive,
	items,
	activeFirstLevel,
	setActiveFirstLevel,
	filtersList,
	isOneLevel,
	errorSelectLevel,
	setColourFilterApplied
}) => {
	const title =
		activeFirstLevel &&
		activeFirstLevel.title.replace(/^.*?(\d+\.\d+) - (.*)$/, '$2 $1');

	const description = getMetafield(activeFirstLevel, 'description') || '';

	const [activeType, setActiveType] = useState(
		filtersList ? filtersList[0] : ''
	);

	const levelOneErrorName = () => {
		if (!activeFirstLevel && errorSelectLevel?.errorLevelOne)
			return errorSelectLevel.errorLevelOne;
	};

	useEffect(() => {
		setActiveFirstLevel(null);
	}, [activeType]);


	return (
		<div className='pdp-selection-level'>
			{!isMobile && (
				<ProductSelectionLevelHeader
					title='Select colour'
					name={title}
					description={description}
					errorLevel={levelOneErrorName}
					showTitle={false}
				/>
			)}
			<div className='pdp-selection-level__flex'>
				{isFilterActive && filtersList && filtersList.length > 1 && (
					<ProductSelectionLevelFilters
						filtersList={filtersList}
						setActiveType={setActiveType}
						activeType={activeType}
						setColourFilterApplied={setColourFilterApplied}
					/>
				)}
				<div className='pdp-selection-level__row pdp-selection-level-row'>
					{(items || []).map((item) => (
						<LevelListItem
							isFilterActive={isFilterActive}
							activeStep={activeStep}
							nextStep={nextStep}
							key={item.storefrontId}
							item={item}
							setActiveLevel={setActiveFirstLevel}
							activeLevel={activeFirstLevel}
							activeType={activeType}
							filtersList={filtersList}
							isOneLevel={isOneLevel}
						/>
					))}
				</div>
				<span className='pdp-selection-level__colour'> {title} </span>
			</div>
		</div>
	);
};

export default ProductSelectionLevelOne;
