import React from 'react';

import './Loader.scss';

const Loader = ({ isFullScreen, className }) => (
		<div className={isFullScreen ? 'custom-spinner-container' : ''}>
			<div className='custom-spinner' />
		</div>
	);

export default Loader;
