// External
import React, { useContext, useEffect, useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import cn from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { StoreContext } from '../../../context/store-context';
import LocalImage from '../../LocalImage';
import { useWindowWidth } from '../../../utils/hooks';

const ProductZoomPhotoSlideContainer = ({
	product,
	thumbsSwiper,
	setSliderNoAnimation,
	slideToIndex,
}) => {
	const { variantSelected, setSlideProperties } = useContext(StoreContext);
	const windowWidth = useWindowWidth();

	const [swiper, setSwiper] = useState(null);
	const [isZoomActive, setIsZoomActive] = useState(false);

	const imagesUrls =
		(product?.media || []).length &&
		product?.media.map((item, index) => ({
			url: item.preview.shopifyId,
			originalSrc: item.preview.image.originalSrc,
			index,
		}));

	useEffect(() => {
		let currentSlide = null;

		const isVariantAndSwiperReady =
			imagesUrls &&
			imagesUrls.length &&
			swiper &&
			variantSelected &&
			variantSelected?.image;

		if (isVariantAndSwiperReady) {
			const variantTitle = variantSelected.title;
			const transformedVariantTitle =
				typeof variantTitle === 'string' &&
				variantTitle.toLowerCase().replaceAll(' ', '-');

			currentSlide = imagesUrls.filter((item) =>
				item.originalSrc.includes(transformedVariantTitle)
			)[0];

			!currentSlide
				? (currentSlide = imagesUrls.filter(
						(item) =>
							item.originalSrc &&
							item.originalSrc.includes(
								variantSelected.image.originalSrc
							)
				  )[0])
				: currentSlide;

			setSliderNoAnimation(true);

			if (currentSlide && Number.isInteger(currentSlide?.index)) {
				swiper.slideTo(currentSlide.index);
			}

			setTimeout(() => {
				setSliderNoAnimation(false);
			}, 100);
		}
	}, [variantSelected, setSlideProperties, swiper]);

	useEffect(() => {
		if (swiper && slideToIndex) {
			swiper.slideTo(slideToIndex);
		}
	}, [slideToIndex, swiper]);

	return (
		<>
			{Array.isArray(product?.media) && product.media.length ? (
				<Swiper
					onSwiper={setSwiper}
					slidesPerView={1}
					simulateTouch='false'
					centeredSlides='false'
					spaceBetween={10}
					className='gallery-zoom-top'
					thumbs={{ swiper: thumbsSwiper }}
					breakpoints={{
						750: { spaceBetween: 0 },
					}}
					preloadImages={false}
					lazy
				>
					{Array.isArray(product?.media) &&
						product?.media.map((image, index) => (
							<SwiperSlide
								className={cn('pdp-zoom-photo-slider__item', {
									'pdp-zoom-photo-slider__item--center':
										(product?.media || []).length === 1,
								})}
								key={`${image.shopifyId}-${index + 1}`}
							>
								<div
									id='pdpPhotoSliderImg'
									className={cn(
										'pdp-zoom-photo-slider__img',
										{
											'pdp-zoom-photo-slider__img--zoom-active':
												isZoomActive,
										}
									)}
								>
									{image.preview.image.gatsbyImageData &&
									!image.preview.image?.originalSrc?.includes(
										'.gif'
									) ? (
										<GatsbyImage
											className='swiper-lazy'
											loading={
												index > 0 ? 'lazy' : 'eager'
											}
											alt={image.preview.image.altText}
											image={image.preview.image
												.gatsbyImageData}
										/>
									) : (
										<LazyLoadImage
											className='swiper-lazy'
											src={
												image.preview.image.originalSrc
											}
											alt={image.shopifyId}
										/>
									)}
								</div>
							</SwiperSlide>
						))}
				</Swiper>
			) : (
				<LocalImage image='no-image.jpg' alt={product?.title} />
			)}

			{windowWidth < 750 && (
				<div
					className='pdp-button-zoom-modal'
					onClick={() => setIsZoomActive(!isZoomActive)}
				>
					{isZoomActive ? (
						<LazyLoadImage
							src='/images/lupe-zoom-out.svg'
							alt='Arrow up'
							effect='blur'
						/>
					) : (
						<LazyLoadImage
							src='/images/lupe-zoom-in.svg'
							alt='Arrow up'
							effect='blur'
						/>
					)}
				</div>
			)}
		</>
	);
};

export default ProductZoomPhotoSlideContainer;
