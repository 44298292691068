import React from 'react';
import loadable from '@loadable/component';

import ButtonGradient from '../../ButtonGradient';
import SocialItem from '../../SocialItem';
import { useWindowWidth } from '../../../utils/hooks';

const FooterLeftSide = loadable(() => import('./FooterLeftSideV2'));
const FooterList = loadable(() => import('./FooterListV2'));
const FooterTopV2 = ({ menu, location }) => {
	const collectionsPreviews = [
		{
			handle: 'blonde-hair-colour',
			image: 'footer/collection-1.png',
			title: 'Products for blonde hair',
			index: 0,
		},
		{
			handle: 'brown-hair-colour',
			image: 'footer/collection-2.webp',
			title: 'Products for brown hair',
			index: 1,
		},
		{
			handle: 'grey-coverage',
			image: 'footer/collection-3.webp',
			title: 'Products for grey hair',
			index: 2,
		},
	];

	const windowWidth = useWindowWidth();

	return (
		<section className='footer__top footer-v2__top'>
			<FooterLeftSide socials={menu?.Socials} location={location} />
			<div className='footer__content footer-v2__content'>
				<FooterList
					title='shop all products'
					list={menu?.ShopMenu}
					collectionsPreviews={collectionsPreviews}
				/>
				<FooterList title='about' list={menu?.Footer} />
				<FooterList title='support' list={menu?.CustomerService} />

				{windowWidth <= 750 && (
					<>
						<ButtonGradient
							isLink
							linkUrl='/pages/consultation'
							buttonText='Find your colour'
							isImageGradient
							className='footer-button__gradient white-text'
						/>
						<div className='footer__rating footer-v2__rating-mobile'>
							<ul className='footer__socials'>
								{(menu?.Socials || []).map((item) => (
									<SocialItem item={item} key={item.id} />
								))}
							</ul>
						</div>
					</>
				)}
			</div>
		</section>
	);
};

export default FooterTopV2;
