import React, { useContext, useState } from 'react';
import { StoreContext } from '../../context/store-context';
import MiracleSystem from './MiracleSystem';
import NormalProduct from './NormalProduct';
import ProductWithVariants from './ProductWithVariants';

import './UpsellPDP.scss';
import { getMetafieldParse } from '../../utils/helpers';

const UpsellPDP = ({ sellingPlanGroup, product }) => {
	const upsellInfo = getMetafieldParse(product, 'upsell_info');

	const [categorySelected, setCategorySelected] = useState(
		upsellInfo[0].productCategory ?? undefined
	);
	const { allProducts } = useContext(StoreContext);

	const findProduct = (productId) => {
		const product = allProducts?.find((product) =>
			product.storefrontId.includes(productId)
		);
		return product;
	};
	const handleSelectCategory = (category) => {
		setCategorySelected(category);
	};

	return (
		<section className='upsell-pdp'>
			<h4 className='upsell-pdp__title'>Complete your look</h4>
			<section className='upsell-pdp__select'>
				<ul>
					{upsellInfo?.map((product) => (
						<li key={product.productCategory}>
							<button
								onClick={() =>
									handleSelectCategory(
										product.productCategory
									)
								}
							>
								<div
									className={`option ${
										categorySelected ===
											product.productCategory &&
										'option-clicked'
									}`}
								>
									{categorySelected ===
										product.productCategory && <div />}
								</div>
								{product.productCategory}
							</button>
						</li>
					))}
				</ul>
			</section>
			<section className='upsell-pdp__product'>
				{upsellInfo?.map((product) => (
					<>
						{product.productCategory === categorySelected && (
							<>
								{product.productType ===
									'TwoVariantsSelect' && (
									<MiracleSystem
										product={findProduct(product.productId)}
										sellingPlanGroup={sellingPlanGroup}
										description={product.productDescription}
										href={product.route}
									/>
								)}
								{product.productType === 'OneVariantSelect' && (
									<ProductWithVariants
										product={findProduct(
											product?.productId
										)}
										description={product?.productDescription}
										href={product?.route}
									/>
								)}

								{product.productType === 'NormalProduct' && (
									<NormalProduct
										product={findProduct(product.productId)}
										description={product.productDescription}
										href={product.route}
									/>
								)}
							</>
						)}
					</>
				))}
			</section>
		</section>
	);
};

export default UpsellPDP;
