import React, { useContext } from 'react';
import { graphql } from 'gatsby';

import SEO from '../../components/SEO';
import ProductPage from './ProductPage';

import { StoreContext } from '../../context/store-context';
import { getMetafield } from '../../utils/helpers';

export default function Product({
	location,
	pageContext: {
		page,
		allBaseColours,
		shadeShotsProducts,
		isPermanentColour,
		rating,
		sellingPlanGroup,
		MiracleSystemSellingPlanGroup
	},
	data: { product = {}, collections = [] },
}) {
	const { allProducts, cart } = useContext(StoreContext);
	collections = collections.nodes.map((node) => ({
		title: node.title.toLowerCase(),
		handle: node.handle,
		products: node.products,
	}));
	const productsList = allProducts;
	const customCanonicalHref = getMetafield(product, 'custom_canonical_url');

	return (
		<>
			<SEO
				page={page}
				location={location}
				product={product}
				title={product.title}
				pageType='PDP'
				customCanonical={customCanonicalHref}
			/>
			<ProductPage
				productsList={productsList ?? []}
				{...{
					product,
					collections,
					cart,
					allBaseColours,
					shadeShotsProducts,
					isPermanentColour,
					rating,
					sellingPlanGroup,
					MiracleSystemSellingPlanGroup
				}}
			/>
		</>
	);
}

export const query = graphql`
	query ($handle: String!) {
		product: shopifyProduct(handle: { eq: $handle }) {
			...ProductCard
		}
		collections: allShopifyCollection(
			filter: { products: { elemMatch: { handle: { eq: $handle } } } }
		) {
			nodes {
				title
				handle
				products {
					handle
					id
					title
				}
			}
		}
	}
`;
