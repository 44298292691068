import React from 'react';

import ButtonLink from './ButtonLink';
import ButtonMain from './ButtonMain';
import ButtonAction from './ButtonAction';
import OutStockData from './OutStockData';
import Loader from '../Loader';

import { isBrowser } from '../../utils/helpers';

import './Button.scss';

const Button = (props) => {
	const url = isBrowser ? window.location.href : '',
		buttonTitle = url.includes('utm_source=google')
			? 'buy now'
			: 'add to bag',
		buttonText = props.buttonText ? props.buttonText : buttonTitle,
		type = props.typeButton || 'button',
		buttonClasses = [
			{ 'button--light': props.isLight },
			{ 'button--dark': props.isDark },
			{ 'button--pink': props.isPink },
			{ 'button--half-width': props.isHalfWidth },
			{ 'button--active': !!props.isActiveClass },
			{ 'button--disabled': props.disabled },
			{ 'button--outline-pink': props.isOutlinePink },
			{ 'button--cart-item': props.isButtonCart },
			{ 'button--pink-basket': props.isPinkBasket },
			{ 'button--no-margin': props.isNoMargin },
			{ 'button--outline-pink-home': props.isPinkOutlineHome },
			{ 'button--homepage': props.isHomepage },
			{ 'button--application-video': props.isApplicationVideo },
		];

	return (
		<>
			{typeof props.availableForSale === 'object' ? (
				<Loader />
			) : (
				<>
					{props.isAction ? (
						<ButtonAction
							{...props}
							buttonClasses={buttonClasses}
							buttonText={buttonText}
							type={type}
							handleClick={props.handleClick}
						/>
					) : (props.isLink && props.availableForSale) ||
					  (props.isLink && props.isNotProduct) ? (
						<ButtonLink
							{...props}
							buttonClasses={buttonClasses}
							buttonText={buttonText}
							type={type}
						/>
					) : (
						<>
							{props.availableForSale ? (
								<ButtonMain
									{...props}
									buttonClasses={buttonClasses}
									buttonText={buttonText}
									type={type}
									isModalUpSell={props.isModalUpSell}
								/>
							) : (
								<OutStockData
									isPdp
									product={props.product}
									isLink={props.isLink}
									isPlp={props.isPlp}
									plpVersion={props.plpVersion}
									isVariant={props.isVariant}
								/>
							)}
						</>
					)}
				</>
			)}
		</>
	);
};

export default Button;
