import React from 'react';
import { Link } from 'gatsby';
import cn from 'classnames';

const ButtonLink = (props) => (
	<Link
		id={props.id || null}
		className={cn(
			`button${props.classname ? ` ${props.classname}` : ''}`,
			props.buttonClasses
		)}
		to={props.linkUrl}
	>
		<span data-test-id='button-link__add--bag' className='button__text' id={props.id || null}>
			{props.buttonText}
		</span>
	</Link>
);

export default ButtonLink;
