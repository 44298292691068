import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { useWindowWidth } from '../../../../utils/hooks';

import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation]);

const ProductGallerySlider = ({
	product,
	thumbsSwiper,
	setThumbsSwiper,
	customPreviewImage,
	mainSwiper
}) => {
	const windowWidth = useWindowWidth();
	const countThumb = (product?.media || []).length;

	const sliderGallerySettings = {
		direction: 'vertical',
		spaceBetween: 10,
		onSwiper: (swiper) => {
			setThumbsSwiper(swiper);
		},
		breakpoints: {
			320: {
				direction: 'horizontal',
				slidesPerView: 7.1,
			},
			750: {
				direction: 'vertical',
				slidesPerView: 4.92,
			},
			1024: {
				direction: 'vertical',
				slidesPerView: 4.1,
			},
			1320: {
				direction: 'vertical',
				spaceBetween: 10,
				slidesPerView: 4.92,
			},
		},
		preloadImages: false,
		lazy: false,
	};

	const goNext = () => {
		if (thumbsSwiper && mainSwiper) {
			thumbsSwiper.slideNext();
			mainSwiper.slideNext();
		}
	};

	const goPrev = () => {
		if (thumbsSwiper && mainSwiper) {
			thumbsSwiper.slidePrev();
			mainSwiper.slidePrev();
		}
	};

	const isMobile = windowWidth < 750;

	return (
		<div className='pdp-images-v2__slider__content'>
			{!isMobile && countThumb > 5 && (
				<button className='pdp-prev-arrow-v2' onClick={goPrev} />
			)}
			<Swiper {...sliderGallerySettings}>
				{customPreviewImage && (
					<SwiperSlide key={customPreviewImage}>
						<LazyLoadImage
							src={customPreviewImage}
							alt={product?.title}
							className='pdp-photo-gallery__img swiper-lazy'
							effect='blur'
						/>
					</SwiperSlide>
				)}
				{Array.isArray(product?.media) &&
					product.media.map((image) => (
						<SwiperSlide
							className='pdp-images-v2__slider__content__slide'
							key={image.shopifyId}
						>
							{image && (
								<div>
									{image?.preview?.image?.gatsbyImageData &&
									!image?.preview?.image?.originalSrc?.includes(
										'.gif'
									) ? (
										<GatsbyImage
											className='swiper-lazy'
											alt={image.shopifyId}
											image={
												image.preview.image
													.gatsbyImageData
											}
										/>
									) : (
										<GatsbyImage
											className='swiper-lazy'
											image={
												image.preview.image
													.gatsbyImageData
											}
											alt={image.shopifyId}
										/>
									)}
								</div>
							)}
						</SwiperSlide>
					))}
			</Swiper>
			{!isMobile && countThumb > 5 && (
				<button className='pdp-next-arrow-v2' onClick={goNext} />
			)}
		</div>
	);
};

export default ProductGallerySlider;