// External
import React, { Suspense, lazy } from 'react';
import Loader from '../Loader';

// Internal
const KlaviyoForm = lazy(() => import('./KlaviyoForm'));

const Klaviyo = ({
	id,
	inHeaderVisited,
	inHeaderNoVisited,
	inFooter,
	inHome,
}) => (
	<Suspense fallback={<Loader />}>
		<KlaviyoForm
			id={id}
			inHeaderVisited={inHeaderVisited}
			inHeaderNoVisited={inHeaderNoVisited}
			inFooter={inFooter}
			inHome={inHome}
		/>
	</Suspense>
);

export default Klaviyo;
