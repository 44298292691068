import React, { memo, useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import CartBody from './CartBody';
import CartEmpty from './CartEmpty';
import { StoreContext } from '../../context/store-context';
import { getGlobalMetafield, decodeBase64 } from '../../utils/helpers';

import './Cart.scss';

const useQuantity = () => {
	const { cart } = useContext(StoreContext);
	const items = cart ? cart.lines.edges : [];
	const total = items.reduce((acc, item) => acc + item.node.quantity, 0);
	return [total !== 0, total];
};

const Cart = ({ productsList }) => {
	const [hasItems, quantity] = useQuantity();
	const [cartLimit, setCartLimit] = useState(0);
	const [selectedValue, setSelectedValue] = useState('');
	const {
		cart,
		isOpen,
		closeCart,
		setModal,
		showModal,
		setSelectedPermanentBase,
		selectedPermanentBase,
		setSelectedPermanentShot,
		selectedPermanentShot,
		cartStories,
	} = useContext(StoreContext);

	useEffect(() => {
		const allMeta =
			JSON.parse(localStorage.getItem('ShopMetafields')) || [];
		const cartLimitField = getGlobalMetafield('cart_limit', allMeta);
		if (cartLimitField.value) {
			setCartLimit(+cartLimitField.value);
		}
	}, [cart]);

	useEffect(() => {
		if (isOpen && cart?.cost && +(cart.cost.totalAmount?.amount || 0)) {
			const cartItems = cart.lines.edges.map((item, idx) => ({
				position: idx,
				id: item.node.merchandise.sku,
				product_id: decodeBase64(item.node.id),
				variant_id: decodeBase64(item.node.merchandise.id),
				name: item.node.title,
				brand: 'JWC',
				quantity: item.node.quantity,
				price: +item.node.merchandise.priceV2.amount,
				variant: item.node.merchandise.title,
			}));

			window['dataLayer'].push({ ecommerce: null });
			window['dataLayer'].push({
				event: 'dl_view_cart',
				event_id: uuid(), // unique uuid for FB conversion API
				cart_total: cart.cost.totalAmount,
				ecommerce: {
					currencyCode: cart.cost.totalAmount.currencyCode,
					actionField: { list: 'Shopping Cart' },
					impressions: cartItems,
				},
			});
		}
	}, [isOpen]);

	return (
		<div
			className={`cart ${isOpen && 'cart--open'} ${
				!quantity && 'cart--error'
			}`}
		>
			<div className='container'>
				<CartBody
					{...{
						hasItems,
						cart,
						quantity,
						closeCart,
						cartLimit,
						isOpen,
						setModal,
						showModal,
						setSelectedPermanentBase,
						selectedPermanentBase,
						setSelectedPermanentShot,
						selectedPermanentShot,
						productsList,
						selectedValue,
						setSelectedValue,
						cartStories,
					}}
				/>
				{!hasItems && <CartEmpty closeCart={closeCart} />}
			</div>
		</div>
	);
};

export default memo(Cart);
