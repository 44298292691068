// External
import React, { useContext, useEffect, useRef, useState } from 'react';

// Internal
import ProductSelectionLevelOne from './ProductSelectionLevelOne';
import Button from '../../../../../components/Button';
import ProductSelectionLevelTwo from './ProductSelectionLevelTwo';
import ProductMiracleActions from './ProductMiracleActions';
import DeliveryRadio from '../../../../../components/DeliveryRadio';
import ProductRecommended from '../../ProductRecommended';

import {
	getMetafield,
	isAvailable,
	mergeArrays,
} from '../../../../../utils/helpers';
import { useWindowWidth } from '../../../../../utils/hooks';
import { StoreContext } from '../../../../../context/store-context';
import ProductSelectionGloss from './ProductSelectionGloss';
import ProductSelectionSmudge from './ProductSelectionSmudge';
import ProductSelectionBlending from './ProductSelectionBlending';
import getUrlParams from '../../../../../helpers/getUrlParams';
import OutStockData from '../../../../../components/Button/OutStockData';

const ProductSelectionLevels = ({
	levelNumber,
	product,
	canSubscribe,
	activeRadio,
	setActiveRadio,
	sellingPlanGroup,
	selectedValue,
	setSelectedValue,
	options,
	discountPrice,
	recommendedProductHandle,
	recommendedTag,
	recommendedText,
	recommendedTextMobile,
	recommendedProduct,
	setRecommended,
	isRecommendedSelected,
	setRecommendedSelected,
	activeFirstLevel,
	setActiveFirstLevel,
}) => {
	const {
		setSlideProperties,
		allProducts,
		setModal,
		showModal,
		setIsProductInfoButtonVisible,
		isProductInfoButtonVisible,
	} = useContext(StoreContext);
	const windowWidth = useWindowWidth();
	const isOneLevel = levelNumber === 1;
	const availableForSale = isAvailable(product);
	const isFilterActive = getMetafield(product, 'filter_active');
	const isMiracleSystemExpert = getMetafield(
		product,
		'is_miracle_system_expert'
	);
	const isMiracleSystemUltimate = getMetafield(
		product,
		'is_miracle_system_ultimate'
	);
	const isHintActive = JSON.parse(
		getMetafield(product, 'is_active_send_hint')
	);

	const fieldData =
		getMetafield(product, 'selection_first_level_list') &&
		getMetafield(product, 'selection_first_level_list').split('|');

	const numberOfSelectionLevels = Number(
		getMetafield(product, 'number_of_selection_levels')
	);

	const getFirstLevelData = () => {
		if (!isOneLevel) {
			return allProducts
				.filter((item) => fieldData.includes(item.handle))
				.sort(
					(a, b) =>
						parseFloat(
							a.title.match(/[-+]?([0-9]*\.[0-9]+|[0-9]+)/)
						).toFixed(1) -
						parseFloat(
							b.title.match(/[-+]?([0-9]*\.[0-9]+|[0-9]+)/)
						).toFixed(1)
				);
		}
		return product.variants.filter(
			(item) => !item.title.toLowerCase().includes('miracle shot')
		);
	};

	const getFiltersArrays = (data) =>
		data &&
		data.map(
			(item) =>
				getMetafield(item, 'filter_type') &&
				getMetafield(item, 'filter_type').split('|')
		);
	const getFiltersList = (arrays) => mergeArrays(arrays);

	const [firstLevelData, setFirstLevelData] = useState(
		allProducts && getFirstLevelData()
	);
	const [filtersArrays, setFiltersArrays] = useState(
		firstLevelData && getFiltersArrays(firstLevelData)
	);
	const [filtersList, setFiltersList] = useState(
		filtersArrays && getFiltersList(filtersArrays)
	);
	const [colourURLParamCounter, setColourURLParamCounter] = useState(0);
	const [colourFilterApplied, setColourFilterApplied] = useState(false);
	const [availableForSaleVariant, setAvailableForSaleVariant] =
		useState(true);

	const [activeSecondLevel, setActiveSecondLevel] = useState(null);
	const [secondLevelData, setSecondLevelData] = useState([]);
	const [activeThirdLevel, setActiveThirdLevel] = useState(null);
	const [thirdLevelData, setThirdLevelData] = useState([]);
	const [activeFourLevel, setActiveFourLevel] = useState(null);
	const [fourLevelData, setFourLevelData] = useState([]);
	const [activeFiveLevel, setActiveFiveLevel] = useState(null);
	const [fiveLevelData, setFiveLevelData] = useState([]);
	const [errorSelectLevel, setErrorSelectLevel] = useState({
		errorLevelOne: '',
		errorLevelTwo: '',
		errorLevelThree: '',
		errorLevelFour: '',
		errorLevelFive: '',
		errorSelect: '',
	});

	const levelValidator = {
		isValidOne: activeFirstLevel,
		isValidTwo: activeSecondLevel,
		isValidThree: activeThirdLevel,
		isValidFour: activeFourLevel,
		isValidFive: activeFiveLevel,
	};

	const optionsObjects = options.map((opt, i) => ({
		value: i,
		label: `Every ${opt} ${opt === '4 Weeks' ? '(most common)' : ''}`,
	}));

	const openModalHint = () => {
		setModal(product, 'hint-modal', '', null);
		showModal(true);
	};

	useEffect(() => {
		const colour = getUrlParams();

		if (colour) {
			const foundFirstLevel = firstLevelData.find((variant) => {
				let sanitizedTitle = variant.title.toLowerCase();
				if (numberOfSelectionLevels !== 1) {
					sanitizedTitle = sanitizedTitle.replace(/[^0-9]/g, '');
				}

				return colour.toLowerCase().includes(sanitizedTitle);
			});

			const foundSecondLevel = product.variants.find((variant) => {
				const sanitizedTitle = variant.title
					.toLowerCase()
					.replace(/[^a-z0-9\s]/gi, '')
					.replace(/\s+/g, ' ');

				return sanitizedTitle === colour.toLowerCase();
			});

			if (foundFirstLevel && numberOfSelectionLevels === 1) {
				setActiveFirstLevel(foundFirstLevel);
				// setAvailableForSaleVariant(foundFirstLevel.availableForSale);
			}
			if (foundFirstLevel && foundSecondLevel && colourFilterApplied) {
				setActiveFirstLevel(foundFirstLevel);
				setActiveSecondLevel(foundSecondLevel);
			}
		}
	}, [colourFilterApplied]);

	useEffect(() => {
		setFirstLevelData(getFirstLevelData());
	}, [allProducts]);

	useEffect(() => {
		setFiltersArrays(getFiltersArrays(firstLevelData));
	}, [firstLevelData]);

	useEffect(() => {
		setFiltersList(getFiltersList(filtersArrays));
	}, [filtersArrays]);

	useEffect(() => {
		setAvailableForSaleVariant(true);

		if (colourURLParamCounter > 1) {
			setActiveSecondLevel(null);
		}
		const currentVariants =
			activeFirstLevel &&
			getMetafield(activeFirstLevel, 'selection_second_level_list') &&
			getMetafield(activeFirstLevel, 'selection_second_level_list')
				.split('|')
				.map((item) => item.split(':')[1]);

		currentVariants &&
			setSecondLevelData(
				product.variants.filter(
					(item) =>
						item.storefrontId &&
						currentVariants.includes(
							item.storefrontId
								.replace('gid://shopify/Product/', '')
								.replace('gid://shopify/ProductVariant/', '')
						)
				)
			);
		setSlideProperties(activeFirstLevel);

		if (numberOfSelectionLevels === 1 && activeFirstLevel) {
			const url = new URL(window.location);
			const sanitizedTitle = activeFirstLevel.title
				.toLowerCase()
				.replace(/[^a-z0-9\s]/gi, '')
				.replace(/\s+/g, ' ');
			url.searchParams.set('colour', sanitizedTitle);
			window.history.replaceState({}, '', url);
		}
		if (colourURLParamCounter <= 1) {
			setColourURLParamCounter(colourURLParamCounter + 1);
		}
		setAvailableForSaleVariant(activeFirstLevel?.availableForSale);
	}, [activeFirstLevel]);

	useEffect(() => {
		const colour = getUrlParams();
		if (!colour) {
			setActiveSecondLevel(null);
			setAvailableForSaleVariant(true);
		}

		setActiveThirdLevel(null);
		setActiveFourLevel(null);
		setActiveFiveLevel(null);
	}, [activeFirstLevel]);

	useEffect(() => {
		setActiveThirdLevel(null);
		setActiveFourLevel(null);
		setActiveFiveLevel(null);
		activeSecondLevel && setSlideProperties(activeSecondLevel);
	}, [activeSecondLevel]);

	useEffect(() => {
		const currentVariantsThree =
			activeFirstLevel &&
			getMetafield(activeFirstLevel, 'selection_third_level_list') &&
			getMetafield(activeFirstLevel, 'selection_third_level_list').split(
				'|'
			);

		if (currentVariantsThree && activeSecondLevel) {
			const titleShadeShot = activeSecondLevel.title
				.split('+')[1]
				.trim()
				.toLowerCase();
			const onlyGlossMatch = currentVariantsThree.filter((item) =>
				item.includes(titleShadeShot.split(' ')[0])
			);

			setThirdLevelData(
				allProducts.filter((item) =>
					onlyGlossMatch.includes(item.handle)
				)
			);
		}

		if (activeSecondLevel) {
			const url = new URL(window.location);
			const sanitizedTitle = activeSecondLevel.title
				.toLowerCase()
				.replace(/[^a-z0-9\s]/gi, '')
				.replace(/\s+/g, ' ');
			url.searchParams.set('colour', sanitizedTitle);
			window.history.replaceState({}, '', url);
		}
	}, [activeSecondLevel]);

	const getCurrentVariants = (activeLevel, activeFirstLevel) => {
		if (
			activeLevel &&
			getMetafield(activeFirstLevel, 'selection_second_level_list')
		) {
			return getMetafield(activeFirstLevel, 'selection_second_level_list')
				.split('|')
				.map((item) => item.split(':')[1]);
		}
		return null;
	};

	const setVariantsData = (currentVariants, setData) => {
		if (currentVariants) {
			const variantsData = product.variants
				.filter(
					(item) =>
						item.storefrontId &&
						currentVariants.includes(
							item.storefrontId
								.replace('gid://shopify/Product/', '')
								.replace('gid://shopify/ProductVariant/', '')
						)
				)
				.slice(0, 1);

			setData(variantsData);
		}
	};

	useEffect(() => {
		const currentVariants = getCurrentVariants(
			activeThirdLevel,
			activeFirstLevel
		);
		setVariantsData(currentVariants, setFourLevelData);
	}, [activeThirdLevel]);

	useEffect(() => {
		const currentVariants = getCurrentVariants(
			activeFourLevel,
			activeFirstLevel
		);
		setVariantsData(currentVariants, setFiveLevelData);
	}, [activeFourLevel]);

	const buttonRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setIsProductInfoButtonVisible(entry.isIntersecting);
			},
			{
				threshold: 0.2,
				rootMargin: '-80px 0px 0px 0px',
			}
		);

		if (buttonRef.current) {
			observer.observe(buttonRef.current);
		}

		return () => {
			if (buttonRef.current) {
				observer.unobserve(buttonRef.current);
			}
		};
	}, []);

	return (
		<div className='pdp-selection'>
			<div className='pdp-selection__desktop'>
				{availableForSale ? (
					<>
						<ProductSelectionLevelOne
							setColourFilterApplied={setColourFilterApplied}
							isFilterActive={isFilterActive}
							isOneLevel={isOneLevel}
							items={firstLevelData}
							filtersList={filtersList}
							activeFirstLevel={activeFirstLevel}
							setActiveFirstLevel={setActiveFirstLevel}
							s
							errorSelectLevel={errorSelectLevel}
						/>
						{!isOneLevel && (
							<ProductSelectionLevelTwo
								isFilterActive={isFilterActive}
								items={secondLevelData}
								activeFirstLevel={activeFirstLevel}
								setActiveSecondLevel={setActiveSecondLevel}
								activeSecondLevel={activeSecondLevel}
								isOneLevel={isOneLevel}
								errorSelectLevel={errorSelectLevel}
							/>
						)}
						{(isMiracleSystemExpert === 'true' ||
							isMiracleSystemUltimate === 'true') && (
							<ProductSelectionGloss
								isFilterActive={isFilterActive}
								items={thirdLevelData}
								errorSelectLevel={errorSelectLevel}
								setActiveThirdLevel={setActiveThirdLevel}
								activeFirstLevel={activeFirstLevel}
								activeSecondLevel={activeSecondLevel}
								activeThirdLevel={activeThirdLevel}
								product={product}
							/>
						)}
						{isMiracleSystemUltimate === 'true' && (
							<>
								<ProductSelectionSmudge
									isFilterActive={isFilterActive}
									items={fourLevelData}
									errorSelectLevel={errorSelectLevel}
									setActiveFourLevel={setActiveFourLevel}
									activeThirdLevel={activeThirdLevel}
									activeFourLevel={activeFourLevel}
								/>

								<ProductSelectionBlending
									isFilterActive={isFilterActive}
									items={fiveLevelData}
									errorSelectLevel={errorSelectLevel}
									setActiveFiveLevel={setActiveFiveLevel}
									activeFourLevel={activeFourLevel}
									activeFiveLevel={activeFiveLevel}
								/>
							</>
						)}
						{isOneLevel ? (
							<>
								{recommendedProductHandle &&
									windowWidth >= 991 && (
										<ProductRecommended
											product={product}
											recommendedProductHandle={
												recommendedProductHandle
											}
											recommendedTag={recommendedTag}
											recommendedText={recommendedText}
											recommendedTextMobile={
												recommendedTextMobile
											}
											setRecommended={setRecommended}
											isRecommendedSelected={
												isRecommendedSelected
											}
											setRecommendedSelected={
												setRecommendedSelected
											}
										/>
									)}

								{availableForSaleVariant ? (
									<>
										<div ref={buttonRef}>
											<Button
												product={product}
												disabled={!activeFirstLevel}
												isActiveClass={activeFirstLevel}
												isRecommendedSelected={
													isRecommendedSelected
												}
												recommendedProduct={
													recommendedProduct
												}
												isBundlePdp
												variantId={
													activeFirstLevel &&
													activeFirstLevel.storefrontId
												}
												availableForSale={
													availableForSale
												}
											/>
										</div>
										<div
											style={{
												opacity:
													isProductInfoButtonVisible
														? 0
														: 1,
												visibility:
													isProductInfoButtonVisible
														? 'hidden'
														: 'visible',
												transition:
													'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
												background:
													isProductInfoButtonVisible
														? 'rgba(0, 0, 0, 0)'
														: 'white',
											}}
											id='add-to-bag-sticky-button'
											className='product-perm-colour-mobile'
										>
											<Button
												product={product}
												disabled={!activeFirstLevel}
												isActiveClass={activeFirstLevel}
												isRecommendedSelected={
													isRecommendedSelected
												}
												recommendedProduct={
													recommendedProduct
												}
												isBundlePdp
												variantId={
													activeFirstLevel &&
													activeFirstLevel.storefrontId
												}
												availableForSale={
													availableForSale
												}
											/>
										</div>
									</>
								) : (
									<OutStockData
										isPdp
										product={product}
										plpVersion='1'
										isVariant
										textOutStock='Variant out of stock'
									/>
								)}
							</>
						) : (
							<>
								{!!canSubscribe && (
									<DeliveryRadio
										activeRadio={activeRadio}
										setActiveRadio={setActiveRadio}
										classname='pdp-miracle-radios'
										product={product}
										levelValidator={levelValidator}
										setErrorSelectLevel={
											setErrorSelectLevel
										}
									/>
								)}
								<div className='flex align-items'>
									<ProductMiracleActions
										{...{
											product,
											canSubscribe,
											discountPrice,
											optionsObjects,
											selectedValue,
											setSelectedValue,
											sellingPlanGroup,
											activeRadio,
											activeSecondLevel,
											activeThirdLevel,
											activeFiveLevel,
											recommendedProduct,
											isRecommendedSelected,
											levelValidator,
											setErrorSelectLevel,
											isHintActive,
											openModalHint,
										}}
										origin='desktop'
									/>
								</div>

								{isHintActive && (
									<div
										style={{
											marginTop: '16px',
										}}
									>
										<Button
											isAction
											isPink
											isNotProduct
											buttonText='Send a hint'
											icon='gift.svg'
											handleClick={openModalHint}
											isNoMargin
										/>
									</div>
								)}
							</>
						)}
					</>
				) : (
					<Button
						product={product}
						disabled={!activeFirstLevel}
						isActiveClass={activeFirstLevel}
						isRecommendedSelected={isRecommendedSelected}
						recommendedProduct={recommendedProduct}
						isBundlePdp
						variantId={
							activeFirstLevel && activeFirstLevel.storefrontId
						}
						availableForSale={availableForSale}
					/>
				)}
			</div>
		</div>
	);
};

export default ProductSelectionLevels;
