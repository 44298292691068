import React from 'react';

import ButtonGradient from '../../ButtonGradient';
import LocalImage from '../../LocalImage';

import './StartOptions.scss';

const StartOptions = () => (
	<section className='start-options'>
		<h2>Not sure where to start?</h2>
		<div className='options'>
			<div className='option'>
				<LocalImage
					image='/home-page/image3.webp'
					alt='Find your perfect products in 2 minutes'
				/>
				<div className='overlay-img'>
					<p className='title'>
						Find your perfect products in 2 minutes
					</p>

					<div className='overlay-img__btn'>
						<ButtonGradient
							isLink
							isImageGradient
							linkUrl='/pages/hair-colour-care-quiz'
							buttonText='Take quiz'
							id='start-options-quiz-button'
						/>
					</div>
				</div>
			</div>
			<div className='option'>
				<LocalImage
					image='/home-page/book-free-vc.webp'
					alt='Speak to an expert'
				/>
				<div className='overlay-img'>
					<p className='title'>Speak to an expert</p>

					<div className='overlay-img__btn'>
						<ButtonGradient
							isLink
							isOutlineWhite
							linkUrl='/pages/book-a-video-hair-consultation'
							buttonText='Book Free Video Consultation'
							id='start-options-video-consultation-button'
						/>
					</div>
				</div>
			</div>
		</div>
	</section>
);

export default StartOptions;
